import React, {useEffect, useState} from 'react';

import "./wheel.css"
import {Button, Collapse, ColorPicker, Input} from "antd";
import {CaretDownOutlined, DeleteFilled, DeleteOutlined} from "@ant-design/icons";

const demoList = [
    "换名",
    "模仿五种动物叫",
    "换头像1天",
    "哄呼",
    "场内S文",
    "大冒险一次",
    "处AQ",
    "奶茶一杯",
    "随机读文",
    "开视频5秒",
    "照片两张",
    "指定撒娇",
    "任意撒娇",
    "指定文本",
    "真心话一个",
    "换后缀",
    "拉丝一分钟",
    "下蹲10个",
    "芜湖一分钟",
    "任意一个要求",
    "早餐",
    "视频mua三下",
    "场外S文",
]

const spinDuring = 5000
const Wheel = () => {
    const radius = 200; // 半径大小
    const strokeWidth = 2; // 描边宽度
    const center = radius + strokeWidth; // 圆心位置
    const circumference = 2 * Math.PI * radius; // 圆周长
    const [color, setColor] = useState({r: 0, g: 0, b: 0});
    const [thisGift, setThisGift] = useState(0);
    const [nextGift, setNextGift] = useState(0);
    const [remoteGift, setRemoteGift] = useState(0);
    const [finalDeg, setFinalDeg] = useState(0);
    const [isSpinning, setIsSpinning] = useState(false);
    const [list, setList] = useState([]);


    // 生成每个分割领域的SVG Path
    const generateSegmentPath = (index) => {
        const angle = (2 * Math.PI) / list.length; // 总角度除以分割数
        const startAngle = index * angle - Math.PI / 2; // 定义每一块的起点角度
        const endAngle = startAngle + angle; // 定义每一块的终点角度

        // 计算起点、终点位置
        const startX = center + radius * Math.cos(startAngle);
        const startY = center + radius * Math.sin(startAngle);
        const endX = center + radius * Math.cos(endAngle);
        const endY = center + radius * Math.sin(endAngle);

        // 标记大弧 (1) 还是小弧 (0)
        const largeArcFlag = angle <= Math.PI ? '0' : '1';

        // 构建SVG路径
        return [
            `M ${center},${center}`, // 圆心开始
            `L ${startX},${startY}`, // 到起点
            `A ${radius},${radius} 0 ${largeArcFlag} 1 ${endX},${endY}`, // 绘制弧线
            'Z' // 关闭路径
        ].join(' ');
    };

    useEffect(() => {
        document.title = "转盘"
        let s = localStorage.getItem("list")
        if (s !== null) {
            let oldList = s.split("||")
            setList(oldList)
            setNextGift(Math.floor(Math.random() * (oldList.length - 1)))
        } else {
            setList(demoList)
            setNextGift(Math.floor(Math.random() * (demoList.length - 1)))
        }
    }, [])

    const saveList = (newList) => {
        localStorage.setItem("list", newList.join("||"))
        setList(newList)
    }


    const spin = () => {
        if (isSpinning) return;
        setFinalDeg(Math.floor(finalDeg / 360 + 8) * 360 + (list.length - nextGift) * 360 / list.length)
        setThisGift(nextGift)
        setNextGift(Math.floor(Math.random() * (list.length - 1)))
        setIsSpinning(true);
        setTimeout(() => {
            setIsSpinning(false)
        }, spinDuring)
    }

    return (
        <div>
            <div style={{position: "sticky", top: 0, zIndex: 999,  backgroundColor: "rgb(" + color.r + "," + color.g + "," + color.b + ")", borderBottom: "1px solid #fff"}}>
                <div style={{display: "flex", justifyContent: "right"}}>
                    <ColorPicker value={color} onChange={(value) => {
                        setColor(value.metaColor);
                    }}/>
                </div>
                <div style={{padding: "0px 40px 50px",display: "flex", justifyContent: "center", width: "calc(100% - 80px)", flexDirection: "column", alignItems: "center", gap: 10}}>


                    <div style={{paddingTop: 20}}>
                        <CaretDownOutlined style={{color: "#fff", fontSize: 20}}/>
                    </div>
                    <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", transform: "rotate(" + finalDeg + "deg)", transition: "transform " + spinDuring + "ms ease", padding: "10px 1px 5px 0"}}>
                        {/*<div style={{position: "absolute", "height": 800, borderLeft: "1px solid #ff0", left: 1}} ></div>*/}
                        {/*<div style={{position: "absolute", "height": 800, borderLeft: "1px solid #ff0", right: 1}} ></div>*/}
                        {/*<div style={{position: "absolute", "width": 800, borderTop: "1px solid #ff0", bottom: 11}} ></div>*/}
                        {/*<div style={{position: "absolute", "width": 800, borderTop: "1px solid #ff0", top: 10}} ></div>*/}
                        <div style={{transform: "rotate(" + (180 / list.length) + "deg)"}}>
                            <svg width={center * 2} height={center * 2} viewBox={`0 0 ${center * 2} ${center * 2}`}>
                                {Array.from({ length: list.length }, (_, index) => (
                                    <>
                                        <path
                                            key={index}
                                            d={generateSegmentPath(index)}
                                            fill={`hsl(${(index / list.length) * 360}, 70%, 70%)`} // 随机颜色
                                            stroke="black"
                                            strokeWidth={strokeWidth}
                                        />
                                    </>
                                ))}
                            </svg>
                        </div>
                        <div style={{position: "absolute", "--r": 360 / list.length + "deg",}} className="rotate-div">
                            {list.map((item, index) => {
                                return (
                                    <div style={{"--n": index, fontWeight: !isSpinning && index === thisGift ? 1000 : null, color: !isSpinning && index === thisGift ? "#fff" : "#000"}}>{item}</div>
                                )
                            })}
                        </div>
                    </div>

                    <div>
                        <Button onClick={spin}>开始</Button>
                    </div>
                </div>
            </div>

            <div style={{padding: 0}}>
                <Collapse items={[
                    {
                        key: '1',
                        label: '配置',
                        children: <>
                            <div style={{padding: 0}}>
                                <div>内容</div>
                                <div style={{display: "grid", gridGap: 10, gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))"}}>
                                    {list.map((item, index) => {
                                        return (
                                            <div style={{display: "flex" ,alignItems: "center"}}>
                                                <div style={{width: 30}}>{index + 1}.</div>
                                                <Input value={item} size="small" onChange={(e) => {
                                                    let newList = [...list]
                                                    newList[index] = e.target.value
                                                    saveList(newList)
                                                }}></Input>
                                                <DeleteOutlined style={{paddingLeft: 10}} onClick={() => {
                                                    let newList = [...list]
                                                    newList.splice(index, 1)
                                                    saveList(newList)
                                                }}/>
                                            </div>
                                        )
                                    })}
                                    <div style={{border: "1px dashed #000", textAlign: "center", borderRadius: 10, padding: 0}} onClick={() => {
                                        saveList([...list, ""])
                                    }}>+ 新增</div>
                                </div>
                            </div>
                        </>
                        ,
                    },
                    {
                        key: '2',
                        label: '高级',
                        children: <>
                            <div style={{padding: 0}}>
                                {/*<div>内幕</div>*/}
                                <div style={{display: "grid", gridGap: 10, gridTemplateColumns: "repeat(auto-fill, minmax(140px, 1fr))"}}>
                                    {list.map((item, index) => {
                                        return (
                                            <div style={{color: "#000", cursor: "pointer", backgroundColor: nextGift === index ? "#d0cdff" : "#f0f0f0", padding: 5, borderRadius: 5, display: "flex" ,alignItems: "center", justifyContent: "center", flex: 1, minWidth: 100}} onClick={() => setNextGift(index)}>
                                                {item}
                                            </div>
                                        )
                                    })}
                                </div>
                                {

                                }
                            </div>
                        </>
                        ,
                    },
                ]} />
            </div>
        </div>
    );
};

export default Wheel;