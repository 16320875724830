import {DeleteIcon, OptionShareIcon, RenameIcon} from "./icon";
import React from "react";

export default () => {


    return (
        <div className="slow-show" style={{display: "var(--model-selecting)",flexDirection: "column", position: "absolute", top: 52, backgroundColor: "#2f2f2f", zIndex: 1000, padding: 8,marginLeft: -8, borderRadius: 8, border: "1px solid #444", width: "100%", maxWidth: 200}}>
            <div style={{padding: 8, paddingTop: 0}}>
                <div style={{fontSize: 12}}>Model</div>
            </div>
            <div style={{padding: 8}}>
                <div style={{fontSize: 14}}>
                    ChatGPT-4o
                </div>
                <div style={{fontSize: 12}}>
                    Best for complex tasks
                </div>
            </div>
            <div style={{padding: 8}}>
                <div style={{fontSize: 14}}>
                    o1-preview
                </div>
                <div style={{fontSize: 12}}>
                    Uses advanced reasoning
                </div>
            </div>
            <div style={{padding: 8}}>
                <div style={{fontSize: 14}}>
                    o1-mini
                </div>
                <div style={{fontSize: 12}}>
                    Fast at reasoning
                </div>
            </div>
            <div style={{padding: 8, paddingBottom: 0}}>
                <div style={{fontSize: 14}}>
                    More models
                </div>
            </div>
        </div>
    )
}