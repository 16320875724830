import React, {useEffect, useRef, useState} from "react";

import History from "./history";
import SiderHeader from "./siderHeader";
import SiderBottom from "./siderBottom";
import MessageList from "./messageList";
import InputBox from "./inputBox";
import {useRequest} from "ahooks";
import {
    doChatRequest,
    doCheck,
    doGetHistory, doGetQuota,
    doGetRecord, requestImage,
    requestImage2Image,
    requestText2Image
} from "../../config/api";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SiderBotton from "./siderBotton";
import Header from "./header";
import {Button, Card, Col, Drawer, Image, Input, InputNumber, message, Row, Slider, Space} from "antd";
import {ShareAltOutlined} from "@ant-design/icons";

const WorkSpace = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const userInfo = useSelector(state => state.user.userInfo)
    const [projectId, setProjectId] = useState(null);
    const [model, setModel] = useState("ChatGPT-3.5");
    const [temperature, setTemperature] = useState(0.95);
    const [top, setTop] = useState(0.7);
    const [listMode, setListMode] = useState(true);
    const [saveMode, setSaveMode] = useState(false);
    const [question, setQuestion] = useState("");
    const [historyList, setHistoryList] = useState([])
    const [tempLoading, setTempLoading] = useState(false)
    const [tempResponse, setTempResponse] = useState("")
    const [msgList, setMsgList] = useState([])
    const {loading: getRecordLoading, runAsync: getRecord} = useRequest(doGetRecord, {manual: true});
    const [clintWidth, setClintWidth] = useState(window.innerWidth)
    const [clientHeight, setClientHeight] = useState(window.innerHeight)
    const [miniSize, setMiniSize] = useState(window.innerWidth < 1200)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [payModal, setPayModal] = useState(false)
    const [deleteProjectId, setDeleteProjectId] = useState([])
    const [quota, setQuota] = useState({
        chat: 0,
        totalChat: 0,
        image: 0,
        totalImage: 0,
    })
    useEffect(() => {
       setModel([0,3].includes(userInfo.role) ? "ChatGPT-4" : "ChatGPT-3.5")
    },[userInfo])
    useEffect(() => {
        window.addEventListener("resize", () => {
            setClintWidth(window.innerWidth)
            setClientHeight(window.innerHeight)
            setMiniSize(window.innerWidth < 1200)
        })
        setInterval(() => {
            setClintWidth(window.innerWidth)
            setClientHeight(window.innerHeight)
        }, 1000)
        refreshQuota()
        doCheck(dispatch).then(r => {
            if (r) {
                // message.success("欢迎回来").then()
            } else {
                if (location.pathname.endsWith("/shaozi")) {
                    navigate("/login/shaozi")
                } else {
                    navigate("/login")
                }
            }
        })
        doGetHistory().then(r => {
            setHistoryList(r ?? [])
        })
    }, [])

    const refreshQuota = () => {
        doGetQuota().then(r => {
            setQuota(r ?? {
                chat: 0,
                totalChat: 0,
                image: 0,
                totalImage: 0,
            })
        })
    }


    const [result, setResult] = useState("")
    const [imageQuestion, setImageQuestion] = useState("");

    const [count, setCount] = useState(0)
    const [skip, setSkip] = useState(false)

    const demoImageList = [
        // "3D render of a cute tropical fish in an aquarium on a dark blue background, digital art",
        // "An armchair in the shape of an avocado",
        // "An expressive oil painting of a basketball player dunking, depicted as an explosion of a nebula",
        // "A photo of a white fur monster standing in a purple room",
        // "An oil painting by Matisse of a humanoid robot playing chess",
        // "A photo of a silhouette of a person in a color lit desert at night",
        // "A blue orange sliced in half laying on a blue floor in front of a blue wall",
        // "A 3D render of an astronaut walking in a green desert",
        // "A futuristic neon lit cyborg face",
        // "A computer from the 90s in the style of vaporwave",
        // "A van Gogh style painting of an American football player",
        // "A cartoon of a monkey in space",
        // "A plush toy robot sitting against a yellow wall",
        // "A bowl of soup that is also a portal to another dimension, digital art",
        // "\"A sea otter with a pearl earring\" by Johannes Vermeer",
        // "A hand drawn sketch of a Porsche 911",
        // "High quality photo of a monkey astronaut",
        // "A cyberpunk monster in a control room",
        // "A photo of Michelangelo's sculpture of David wearing headphones djing",
        // "An abstract painting of artificial intelligence",
        // "3D render of a cute tropical fish in an aquarium on a dark blue background, digital art",
        // "An armchair in the shape of an avocado",
        // "An expressive oil painting of a basketball player dunking, depicted as an explosion of a nebula",
        // "A photo of a white fur monster standing in a purple room",
        "https://10kv.oss-cn-shanghai.aliyuncs.com/image/08cb50b7-b4cc-4e06-a2e3-4ba4e8d90f5a.png",
        "https://10kv.oss-cn-shanghai.aliyuncs.com/image/0ba17852-414e-4faf-804c-1a68a40480fd.png",
        "https://10kv.oss-cn-shanghai.aliyuncs.com/image/0f1041b5-f74d-477e-b6f0-4d006c1d1233.png",
        "https://10kv.oss-cn-shanghai.aliyuncs.com/image/0f13a699-c6e1-495f-9b72-1cb00b6c10a0.png",
        "https://10kv.oss-cn-shanghai.aliyuncs.com/image/1b07eb4b-fcf9-4ece-bc8e-62adbde9bfce.png",
        "https://10kv.oss-cn-shanghai.aliyuncs.com/image/27dd53b1-d6b2-4056-93dd-baa568bea86d.png",
        "https://10kv.oss-cn-shanghai.aliyuncs.com/image/bbf75488-7d34-4195-a317-3f718473aa5d.png",
        "https://10kv.oss-cn-shanghai.aliyuncs.com/image/f306695f-6c20-4afd-82e0-d7642430e56e.png",
        "https://10kv.oss-cn-shanghai.aliyuncs.com/image/a094a141-c74b-40d1-8f86-56509a022f6c.png",
    ]

    const {loading: imageLoading, runAsync: doRequestImage} = useRequest(requestImage, {manual: true});

    const inputQuestion = (e) => {
        setImageQuestion(e.target.value.replaceAll("\n", ""))
    }

    const submit = (e) => {
        if (e.keyCode === 229) {
            return
        }
        if (!imageQuestion) {
            message.warning("请输入问题").then()
            return
        }
        if (imageLoading) {
            message.info("上一条任务正在计算中").then()
            return
        }
        // setQuestion("")
        setCount(0)
        let a = 0
        let interval = setInterval(() => {
            setCount(a)
            a += 10
        }, 10)
        doRequestImage({
            // requestText2Image({
            prompt: imageQuestion,
            steps: 150
        }).then(r => {
            // console.log(r.images[0])
            // setBase64(r.images[0])
            setResult(r)
            clearInterval(interval)
        })
    }

    const siderHeader =
        <SiderHeader
            newChat={() => {
                if (tempLoading) {
                    window.location.reload()
                }
                setModel("ChatGPT-4")
                document.title = "10KV-GPT"
                setProjectId(null)
                setMsgList([])
                setDrawerOpen(false)
            }}
            tempLoading={tempLoading}
            setMiniSize={() => {
                if (clintWidth > 920) {
                    setMiniSize(!miniSize)
                } else {
                    setDrawerOpen(!drawerOpen)
                }
            }}
            model={model}
            setModel={(e) => {
                setModel(e)
                setProjectId(null)
                setDrawerOpen(false)
            }}
        />

    const historyElement =
        <History
            tempLoading={tempLoading}
            setTempLoading={setTempLoading}
            projectId={projectId}
            setProjectId={(id) => {
                if (id !== projectId) {
                    setProjectId(id)
                    getRecord({projectId: id}).then(r => {
                        setMsgList(r.msgList)
                        setModel(r.model ?? "")
                        // console.log(r.msgList[1].content)
                    })
                    setDrawerOpen(false)
                }
            }}
            deleteProjectId={deleteProjectId}
            historyList={historyList}
            setHistoryList={(e) => {
                setHistoryList(e)
                let newProjectId = e.length === 0 ? null : e[0].id
                setProjectId(newProjectId)
                getRecord({projectId: newProjectId}).then(r => {
                    setMsgList(r.msgList)
                })
            }}
        />

    const siderBottomElement =
        <SiderBottom quota={quota} setPayModal={(e) => setPayModal(e)}
                     deleteAll={() => setDeleteProjectId(historyList)}/>

    return (
        <div className="layout slow-show" style={{width: clintWidth, height: window.innerHeight}}>
            <Header
                newChat={() => {
                    setModel("ChatGPT-3.5")
                    setProjectId(null)
                    setMsgList([])
                }}
                openDrawer={() => setDrawerOpen(true)}
                clintWidth={clintWidth}
            />
            <Drawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                placement="left"
                closable={false}
                width={260}
                // bodyStyle={{padding: 0, backgroundColor: "#202123", color: "#ececf1"}}
                styles={{body:{padding: 0, backgroundColor: "#202123", color: "#ececf1"}}}
            >
                <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
                    <div style={{
                        backgroundColor: "#121213",
                        height: 60,
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 10
                    }}>
                        <div style={{flex: 1}}>
                            {/*<img src="https://ebai.oss-cn-shanghai.aliyuncs.com/10kv-logo-color.png" alt="" style={{height: 40, margin: "10px 0 0 50px"}}/>*/}
                            <img src="https://ebai.oss-cn-shanghai.aliyuncs.com/logo%2Btext.png" alt=""
                                 style={{height: 40, margin: "10px 0 0 0px"}}/>
                        </div>
                    </div>
                    <div style={{flex: 1, overflow: "auto", display: "flex", flexDirection: "column", padding: 8}}>
                        {siderHeader}
                        <div style={{flex: 1, overflow: "auto", marginTop: 8}}>
                            {historyElement}
                        </div>
                        {siderBottomElement}
                    </div>
                </div>
            </Drawer>
            <div style={{display: "flex", flex: 1, overflow: "auto"}}>
                <SiderBotton miniSize={miniSize} setMiniSize={() => {
                    if (clintWidth > 920) {
                        setMiniSize(!miniSize)
                    } else {
                        setDrawerOpen(!drawerOpen)
                    }
                }} clintWidth={clintWidth}/>
                <div className="sider" style={{width: (clintWidth < 920 || miniSize) ? 0 : 260}}>
                    <div className="sider-inner">
                        {siderHeader}
                        <div style={{flex: 1, overflow: "auto", marginTop: 8}}>
                            {historyElement}
                        </div>
                        {siderBottomElement}
                    </div>
                </div>
                {model.startsWith("SD") ?
                    <div className="main">
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <div className="slow-show" style={{padding: 20, maxWidth: 800, flex: 1, height: "100%", overflow: "auto", filter: getRecordLoading ? "blur(5px)" : null, transition: "0.2s"}}>
                                <div>Prompt：</div>
                                <Input.TextArea value={imageQuestion}
                                                onChange={inputQuestion}
                                                onPressEnter={submit}
                                                placeHolder="Enter Prompt"
                                                minLine={4}
                                                showCount={{
                                                    formatter: (info) => <div style={{color: "#888"}}>{info.count}</div>
                                                }}
                                                autoSize={{minRows: 5}}
                                ></Input.TextArea>
                                <Button block style={{marginTop: 30}} loading={imageLoading} type="primary"
                                        onClick={submit}>SUBMIT</Button>
                                <div style={{color: "#888", marginTop: 20}}>
                                    {
                                        imageLoading ?
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}>
                                                <div style={{display: "flex", gap: 10}}>
                                                    耗时：
                                                    <div style={{width: 30, textAlign: "right"}}>{count / 1000}</div>
                                                    秒
                                                </div>
                                                <div className="loading-2" style={{margin: "50px 0 50px"}}>
                                                    <div className="shape shape-4">
                                                        <div className="shape-4-top"></div>
                                                        <div className="shape-4-bottom"></div>
                                                        <div className="shape-4-eye"></div>
                                                    </div>
                                                    <div className="shape shape-1"></div>
                                                    <div className="shape shape-2"></div>
                                                    <div className="shape shape-3"></div>
                                                </div>
                                            </div> :
                                            result !== "" ?
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    gap: 20
                                                }}>
                                                    {/*<img src={`data:image/png;base64,${base64}`} alt="" style={{width: "100%", maxWidth: 512}}/>*/}
                                                    <img src={result} alt="" style={{width: "100%", maxWidth: 512}}/>
                                                    <div>总共用时 {count / 1000} 秒</div>
                                                </div> :
                                                <div>
                                                    {/*<div style={{color: "#ddd"}}>Example：</div>*/}
                                                    {/*<div style={{display: "flex", flexWrap: "wrap", gap: 10}}>*/}
                                                    {/*    {demoImageList.map((item, index) =>*/}
                                                    {/*        <div style={{*/}
                                                    {/*            flex: 1,*/}
                                                    {/*            minWidth: 135,*/}
                                                    {/*            padding: 10,*/}
                                                    {/*            backgroundColor: "rgb(162,160,242)",*/}
                                                    {/*            borderRadius: 10*/}
                                                    {/*        }}>*/}
                                                    {/*            <Image*/}
                                                    {/*                key={index}*/}
                                                    {/*                src={"https://cdn.openai.com/labs/images/" + item + ".webp?v=1"}*/}
                                                    {/*            />*/}
                                                    {/*            <div style={{marginTop: 10, color: "#eee"}}>{item}</div>*/}
                                                    {/*        </div>*/}
                                                    {/*    )}*/}
                                                    {/*</div>*/}
                                                    <div className="image-container">
                                                        {demoImageList.map(item => <div className="image-item" style={{backgroundImage: "url(" + item + ")", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}></div>)}
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="main slow-show">
                        <div className="main-inner" id="msgList" >
                            <MessageList
                                setQuestion={setQuestion}
                                msgList={msgList}
                                loading={getRecordLoading}
                                model={model}
                                setModel={(m) => setModel(m)}
                                tempLoading={tempLoading}
                                tempResponse={tempResponse}
                                clintWidth={clintWidth}
                                payModal={payModal} setPayModal={(e) => setPayModal(e)}
                                setMsgList={setMsgList}
                                projectId = {projectId}
                                setHistoryList = {setHistoryList}
                                setProjectId = {setProjectId}
                            />
                            <div style={{height: 170}}></div>
                        </div>

                        <div className="input-box">
                            <InputBox
                                userInfo={userInfo}
                                msgList={msgList}
                                setMsgList={(list) => setMsgList(list)}
                                model={model}
                                temperature={temperature}
                                top={top}
                                projectId={projectId}
                                listMode={listMode}
                                saveMode={saveMode}
                                setProjectId={(i) => {
                                    if (projectId === null) {
                                        doGetHistory().then(r => setHistoryList(r))
                                    }
                                    setProjectId(i)
                                }}
                                tempLoading={tempLoading}
                                setTempLoading={(e) => setTempLoading(e)}
                                setTempResponse={(e) => setTempResponse(e)}
                                clintWidth={clintWidth}
                                question={question}
                                setQuestion={(e) => setQuestion(e)}
                                refreshQuota={refreshQuota}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default WorkSpace;
//
// const InfoCard = ({ title, href, index, desc }) => {
//
//     return (
//         <div
//             style={{
//                 backgroundColor: "#ffffff",
//                 boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)",
//                 borderRadius: '8px',
//                 fontSize: '14px',
//                 color: "rgba(0, 0, 0, 0.65)",
//                 lineHeight: '22px',
//                 padding: '16px 19px',
//                 minWidth: '220px',
//                 flex: 1,
//             }}
//         >
//             <div
//                 style={{
//                     display: 'flex',
//                     gap: '4px',
//                     alignItems: 'center',
//                 }}
//             >
//                 <div
//                     style={{
//                         width: 48,
//                         height: 48,
//                         color: '#FFF',
//                         fontWeight: 'bold',
//                         backgroundImage:
//                             "url('https://gw.alipayobjects.com/zos/bmw-prod/daaf8d50-8e6d-4251-905d-676a24ddfa12.svg')",
//                     }}
//                 >
//                     <div style={{padding: "8px 0 0 20px"}}>
//                         {index}
//                     </div>
//                 </div>
//                 <div
//                     style={{
//                         fontSize: '16px',
//                         color: "rgba(0, 0, 0, 0.88)",
//                         paddingBottom: 8,
//                     }}
//                 >
//                     {title}
//                 </div>
//             </div>
//             <div
//                 style={{
//                     fontSize: '14px',
//                     color: "rgba(0, 0, 0, 0.65)",
//                     textAlign: 'justify',
//                     lineHeight: '22px',
//                     marginBottom: 8,
//                 }}
//             >
//                 {desc}
//             </div>
//             <a href={href} target="_blank" rel="noreferrer">
//                 了解更多 {'>'}
//             </a>
//         </div>
//     );
// };
//
// return (
//     <Card
//         style={{
//             borderRadius: 8,
//         }}
//         bodyStyle={{
//             backgroundImage:
//                 'background-image: linear-gradient(75deg, #1A1B1F 0%, #191C1F 100%)'
//         }}
//     >
//         <div
//             style={{
//                 backgroundPosition: '100% -30%',
//                 backgroundRepeat: 'no-repeat',
//                 backgroundSize: '274px auto',
//                 backgroundImage:
//                     "url('https://gw.alipayobjects.com/mdn/rms_a9745b/afts/img/A*BuFmQqsB2iAAAAAAAAAAAAAAARQnAQ')",
//             }}
//         >
//             <div
//                 style={{
//                     fontSize: '20px',
//                     color: "rgba(0, 0, 0, 0.88)",
//                 }}
//             >
//                 欢迎使用 Ant Design Pro
//             </div>
//             <p
//                 style={{
//                     fontSize: '14px',
//                     color: "rgba(0, 0, 0, 0.65)",
//                     lineHeight: '22px',
//                     marginTop: 16,
//                     marginBottom: 32,
//                     width: '65%',
//                 }}
//             >
//                 Ant Design Pro 是一个整合了 umi，Ant Design 和 ProComponents
//                 的脚手架方案。致力于在设计规范和基础组件的基础上，继续向上构建，提炼出典型模板/业务组件/配套设计资源，进一步提升企业级中后台产品设计研发过程中的『用户』和『设计者』的体验。
//             </p>
//             <div
//                 style={{
//                     display: 'flex',
//                     flexWrap: 'wrap',
//                     gap: 16,
//                 }}
//             >
//                 <InfoCard
//                     index={1}
//                     href="https://umijs.org/docs/introduce/introduce"
//                     title="了解 umi"
//                     desc="umi 是一个可扩展的企业级前端应用框架,umi 以路由为基础的，同时支持配置式路由和约定式路由，保证路由的功能完备，并以此进行功能扩展。"
//                 />
//                 <InfoCard
//                     index={2}
//                     title="了解 ant design"
//                     href="https://ant.design"
//                     desc="antd 是基于 Ant Design 设计体系的 React UI 组件库，主要用于研发企业级中后台产品。"
//                 />
//                 <InfoCard
//                     index={3}
//                     title="了解 Pro Components"
//                     href="https://procomponents.ant.design"
//                     desc="ProComponents 是一个基于 Ant Design 做了更高抽象的模板组件，以 一个组件就是一个页面为开发理念，为中后台开发带来更好的体验。"
//                 />
//             </div>
//         </div>
//     </Card>
// )
