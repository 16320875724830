import React from "react";
import {MessageOutlined, PictureOutlined} from "@ant-design/icons";

export default ({tempLoading, newChat, setMiniSize,model, setModel}) => {
    return (
        <>
            {/*<img src="https://ebai.oss-cn-shanghai.aliyuncs.com/logo%2Btext.png" alt="" style={{height: 30, width: 75, marginBottom: 8}}/>*/}

            <div style={{display: "flex", gap: "0.5rem", whiteSpace: "nowrap", height: 44}}>
                <div className="button" style={{flex: 1, borderRadius: 5, border: "1px solid #565869", display: "flex", alignItems: "center", gap: 10}} onClick={newChat}>
                    <div style={{height: 16, width: 16, marginLeft: 10}}>
                        <svg stroke="currentColor" fill="none"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                    </div>
                    新建聊天
                </div>
                <div className="button" style={{width: 44, borderRadius: 5, border: "1px solid #565869", display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => setMiniSize()}>
                   <div style={{height: 16, width: 16}}>
                       <svg stroke="currentColor" fill="none"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                           <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                           <line x1="9" y1="3" x2="9" y2="21"></line>
                       </svg>
                   </div>
                </div>
            </div>
            {model.startsWith("ChatGPT") ?
                <div className="button no-select" style={{marginTop: 10, height: 44,borderRadius: 5, border: "1px solid #565869", display: "flex", alignItems: "center", gap: 10,  whiteSpace: "nowrap"}} onClick={() => {
                    if (tempLoading) {
                        window.location.reload()
                    }
                    setModel("SD")
                    document.title = "10KV-Graphic"
                }}>
                    <PictureOutlined style={{margin: "0 2px 0 12px", fontSize: 14, color: "#919194"}}/>
                    10KV-Graphic 作图
                </div> :
                <div className="button no-select" style={{marginTop: 10, height: 44,borderRadius: 5, border: "1px solid #565869", display: "flex", alignItems: "center", gap: 10,  whiteSpace: "nowrap"}} onClick={() => {
                    if (tempLoading) {
                        window.location.reload()
                    }
                    setModel("ChatGPT-3.5")
                    document.title = "10KV-GPT"
                }}>
                    <MessageOutlined style={{margin: "0 2px 0 12px", fontSize: 14, color: "#919194"}}/>
                    Try 10KV-GPT
                </div>
            }

        </>
    )
}
