import React, {useEffect, useState} from "react";
import {useRequest} from "ahooks";
import {doDeleteHistory, doGetHistory} from "../../config/api";
import {Empty} from "antd";
import {useNavigate} from "react-router-dom";

export default ({projectId, setProjectId, historyList, setHistoryList, deleteProjectId, tempLoading, setTempLoading}) => {

    const {loading: deleteHistoryLoading, runAsync: deleteHistory} = useRequest(doDeleteHistory, {manual: true});

    const navigate = useNavigate();

    useEffect(() => {
        if (deleteProjectId.length === 0) {
            return
        }
        deleteProject(deleteProjectId.map(item => item.id))
    }, [deleteProjectId])
    const deleteProject = (list) => {
        deleteHistory(list).then(r => {
            setHistoryList(r)
        })
    }

    return (
        <div style={{whiteSpace: "nowrap", filter: deleteHistoryLoading ? "blur(5px)" : ""}}>
            {historyList.length !== 0 ?
                <>
                    <div style={{fontSize: "0.6rem", margin: "15px 0 10px 12px", color: "#8d8d9f"}}>最近七天</div>
                    <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                        {historyList.map((item, index) =>
                            <div key={index} style={{backgroundColor: projectId === item.id ? "#343541" : ""}} className="button history-item" onClick={() => {
                                setProjectId(item.id)
                                if (tempLoading) {
                                    window.location.reload()
                                }
                            }}>
                                <div style={{width: 16, marginRight: 10}}>
                                    <svg stroke="currentColor" fill="none"  viewBox="0 0 24 24"
                                         height="1em"
                                         width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                    </svg>
                                </div>
                                <div style={{flex: 1, overflowX: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}} className="no-select">{item.title}</div>
                                <div style={{display: projectId === item.id ? "flex" : "none", gap: 5, width: 50}}>
                                    <svg stroke="currentColor" fill="none"  viewBox="0 0 24 24"
                                         height="1em"
                                         width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 20h9"></path>
                                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                    </svg>
                                    <svg stroke="currentColor" fill="none"  viewBox="0 0 24 24"
                                         height="1em"
                                         width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                                        <polyline points="16 6 12 2 8 6"></polyline>
                                        <line x1="12" y1="2" x2="12" y2="15"></line>
                                    </svg>
                                    <svg stroke="currentColor" fill="none"  viewBox="0 0 24 24"
                                         height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg"
                                         onClick={() => deleteProject([item.id])}
                                    >
                                        <polyline points="3 6 5 6 21 6"></polyline>
                                        <path
                                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>
                </> :
            <div style={{margin: "10px 0"}}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{opacity: 0.5}} description={<div style={{color: "#fff", marginTop: 20}}>没有历史记录</div>}/>
            </div>}
        </div>
    )
}
