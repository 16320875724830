import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useRequest} from "ahooks";
import {message} from "antd";
import {checkOrder} from "../../config/api";

const Payback = () => {

    const navigate = useNavigate();
    // const {loading: loadingCheck, runAsync: doCheckOrder} = useRequest(checkOrder, {manual: true});
    const [time, setTimes] = useState(0)

    useEffect(() => {
        if (window.location.search.substring(0, 7) === "?money=") {
            navigate("/payback")
        }
        let i = 1
        let interval1 = setInterval(() => {
            setTimes(i)
            i++
        }, 1000)
        checkOrder().then(r => {
            if (r === 2) {
                clearInterval(interval1)
                message.success("交易成功").then()
                navigate("/")
            }
        })
        let interval2 = setInterval(() => {
            checkOrder().then(r => {
                if (r === 2) {
                    clearInterval(interval1)
                    clearInterval(interval2)
                    message.success("交易成功").then()
                    navigate("/")
                }
            })

            if (i > 30) {
                clearInterval(interval1)
                clearInterval(interval2)
                message.error("交易超时").then()
                navigate("/")
            }
        }, 5000)

    }, [])

    return (
        <div style={{color: "white", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#343541", height: "100vh", width: "100vw"}}>
            <div className="hourglass" style={{margin: "40px 0"}}></div>
                <div>
                    <div>订单验证中..</div>
                    <div>{time}s</div>
                </div>
        </div>
    );
};

export default Payback;