import React, {useEffect, useState} from "react";
import {DeleteIcon, MoreIcon, OptionShareIcon, RenameIcon, ShareIcon} from "./icon";
import {doGetHistory, doDeleteHistory} from "../../config/api";
import {CheckOutlined} from "@ant-design/icons";

export default ({projectId, changeProject, question, showLogin}) => {

    const [historyList, setHistoryList] = useState({})
    const [data, setData] = useState([])
    const [idData, setIdData] = useState([])
    const [renameId, setRenameId] = useState("")

    useEffect(() => {
        if (projectId === null) {
            doGetHistory().then(r => {
                setData(r)
                setIdData(r?.map(item => item.id))
                setHistoryList(groupDate(r ?? []))
            })
        } else {
            if (!idData.includes(projectId)) {
                let newData = data
                newData.unshift({id: projectId, title: question})
                setData(newData)
                setIdData(newData.map(item => item.id))
                let newHistoryList = historyList
                newHistoryList["Today"]?.unshift({id: projectId, title: question})
                setHistoryList(newHistoryList)
            }

            // console.log(projectId)
            // console.log(historyList?.["今天"]?.[0]?.id)
            // if (!data.includes(projectId)) {
            //     console.log(111)
            //     let newData = data
            //     newData.unshift(projectId)
            //     setData(newData)
            //     historyList["今天"].unshift({id: projectId, title: question})
            // } else if (projectId === historyList?.["今天"]?.[0]?.id) {
            //     console.log(222)
            //     let newHistoryList = historyList
            //     newHistoryList["今天"][0].title = question
            //     setHistoryList(newHistoryList)
            // }


        }
    }, [projectId, showLogin])

    const deleteProject = (list) => {
        let newData = data.filter(item => {
            return !list.includes(item.id)
        })
        setHistoryList(groupDate(newData))
        setData(newData)
        doDeleteHistory(list).then(r => {
            // setHistoryList(groupDate(r ?? []))
        })
    }

    const groupDate = (items) => {
        //
        // if (items === undefined) return

        const now = new Date();

        const getStartOfDay = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

        const today = getStartOfDay(now);

        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const startOfLastWeek = new Date(today);
        startOfLastWeek.setDate(today.getDate() - 6);

        const startOfLastMonth = new Date(today);
        startOfLastMonth.setMonth(today.getMonth() - 1);

        const getStartOfMonth = (year, month) => new Date(year, month, 1);

        const months = [];
        for (let i = 1; i <= 6; i++) {
            const month = now.getMonth() - i;
            months.push(getStartOfMonth(now.getFullYear(), month));
        }


        const result = {
            "Today": [],
            "Yesterday": [],
            "Last week": [],
            "Last month": [],
            [`${months[0].getMonth() + 1}月`]: [],
            [`${months[1].getMonth() + 1}月`]: [],
            [`${months[2].getMonth() + 1}月`]: [],
            [`${months[3].getMonth() + 1}月`]: [],
            [`${months[4].getMonth() + 1}月`]: [],
            [`${months[5].getMonth() + 1}月`]: [],
            "Long time ago": []
        };

        items.forEach(item => {
            const itemDate = new Date(item.time);
            const startOfItemDay = getStartOfDay(itemDate);

            if (startOfItemDay.getTime() === today.getTime()) {
                result["Today"].push(item);
            } else if (startOfItemDay.getTime() === yesterday.getTime()) {
                result["Yesterday"].push(item);
            } else if (startOfItemDay >= startOfLastWeek) {
                result["Last week"].push(item);
            } else if (startOfItemDay >= startOfLastMonth) {
                result["Last month"].push(item);
            } else if (startOfItemDay >= months[0]) {
                result[`${months[0].getMonth() + 1}月`].push(item);
            } else if (startOfItemDay >= months[1]) {
                result[`${months[1].getMonth() + 1}月`].push(item);
            } else if (startOfItemDay >= months[2]) {
                result[`${months[2].getMonth() + 1}月`].push(item);
            } else if (startOfItemDay >= months[3]) {
                result[`${months[3].getMonth() + 1}月`].push(item);
            } else if (startOfItemDay >= months[4]) {
                result[`${months[4].getMonth() + 1}月`].push(item);
            } else if (startOfItemDay >= months[5]) {
                result[`${months[5].getMonth() + 1}月`].push(item);
            } else {
                result["Long time ago"].push(item);
            }
        });

        for (const key in result) {
            if (result[key].length === 0) {
                delete result[key];
            }
        }

        return result;
    }

    return (
        <div style={{flex: 1, overflowY: "auto", display: "flex", flexDirection: "column", gap: 30, padding: "0 10px 0 10px"}}>
            {Object.keys(historyList)?.map(group => (
                <div key={group}>
                    <div style={{padding: "0 0 10px 10px", userSelect: "none"}}>{group}</div>
                    <div>
                        {historyList[group]?.map(history => (
                            <div key={history.id} className={"menu-item " + (projectId === history.id ? "menu-item-active" : "")} style={{ display: "flex", borderRadius: 8, cursor: "pointer", userSelect: "none", position: "relative", justifyContent: "end", alignItems: "center", padding: "0 8px", gap: 5}}>
                                <div style={{flex: 1, whiteSpace: "nowrap", overflow: "hidden", position: "relative"}} >
                                    {
                                        renameId === history.id ?
                                            <div style={{display: "flex", alignItems: "center", gap: 5, padding: "8px 0"}}>
                                                <input id="rename-input" className="rename-input" style={{width: "100%", backgroundColor: "#0000", color: "#fff", border: "1.5px solid #fff", marginLeft: 2}} defaultValue={history.title} onBlur={() => setRenameId("")}/>
                                                <CheckOutlined />
                                            </div>:
                                            <>
                                                <div style={{display: "inline-block", padding: "8px 0"}} onClick={() => changeProject(history.id)}>{history.title}</div>
                                                <div className="menu-item-fade" style={{position: "absolute", right: 0, top: 0, bottom: 0, width: 20, pointerEvents: "none", borderRadius: 8}}></div>
                                            </>
                                    }
                                </div>
                                {renameId === history.id ? null :
                                    <div className="menu-more-visible" style={{alignItems: "center", height: 38}}>
                                        <MoreIcon/>
                                        <div className="options-div" style={{position: "absolute",right: 0, top: 38, backgroundColor: "#2f2f2f", zIndex: 1000, padding: 8, borderRadius: 8, display: "var(--options-visible)", flexDirection: "column"}}>
                                            <div style={{padding: "5px 10px", borderRadius: 5}}>
                                                <OptionShareIcon/>
                                                <div>Share</div>
                                            </div>
                                            <div style={{padding: "5px 10px", borderRadius: 5}} onClick={() => {
                                                setRenameId(history.id)
                                                setTimeout(() => document.getElementById("rename-input").focus(), 50)
                                                }}>
                                                <RenameIcon/>
                                                <div>Rename</div>
                                            </div>
                                            <div style={{padding: "5px 10px", borderRadius: 5, color: "#f00"}} onClick={() => deleteProject([history.id])}>
                                                <DeleteIcon/>
                                                <div>Delete</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )

}
