import React, {useEffect, useState} from "react";
import {ChatGPTIcon, ChatGPTIconBig, CopyIcon} from "./icon";

import ReactMarkdown from 'react-markdown';           // 用于解析 Markdown
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';  // 代码高亮
import { oneDark as highlighterStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {message} from "antd";
import {useRequest} from "ahooks";
import {doGetRecord} from "../../config/api";

// 定义语言到 react-syntax-highlighter 的映射关系

const copyCode = (code) => {
    let tmpInput = document.createElement("textarea");
    document.body.appendChild(tmpInput);
    tmpInput.value = code.trim();
    tmpInput.select();
    document.execCommand("copy");
    tmpInput.remove();
    message.success("复制成功").then()
}

const CodeBlock = ({ language, value }) => {
    return (
        <div className="message-code-body" style={{backgroundColor: "#2f2f2f", borderRadius: 5}}>
            <div style={{padding: "5px 15px 0 15px", display: "flex", gap: 5, alignItems: "center"}}>
                <div>{language}</div>
                <div style={{flex: 1}}></div>
                <div style={{cursor: "pointer", display: "flex", alignItems: "center"}}><CopyIcon/></div>
                <div style={{cursor: "pointer", userSelect: "none"}} onClick={() => copyCode(value)}>复制代码</div>
            </div>
            <SyntaxHighlighter language={language || 'text'} style={highlighterStyle}>
                {value}
            </SyntaxHighlighter>
        </div>
    );
};

const ChatGPTResponse = ({ content }) => {
    return (
        <div style={{maxWidth: 746, display: "flex", flexDirection: "column", overflowWrap: "break-word"}}>
            <ReactMarkdown
                components={{
                    // 自定义代码块组件，适配 Markdown 代码块显示
                    code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '');
                        return !inline && match ? (
                            <CodeBlock
                                language={match[1]}
                                value={String(children).replace(/\n$/, '')}
                                {...props}
                            />
                        ) : (
                            <code className={className} {...props} style={{backgroundColor: "#414141", padding: 4, borderRadius: 4, fontSize: 12}}>
                                {children}
                            </code>
                        );
                    },
                }}
            >
                {content}
            </ReactMarkdown>
        </div>
    );
};

export default ({msgList, loading}) => {

    return (
        <>
            <div id="messageList" style={{flex: 1, display: "flex", justifyContent: "center", overflowY: "auto", padding: "0 10px 0 20px"}}>
                <div style={{width: "100%", display: "flex", flexDirection: "column", maxWidth: 800}}>
                    {msgList?.map((item, index) => (
                        item.role === "user" ?
                            <div key={item.id + index.toString()} style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "end", maxWidth: 800}}>
                                <div style={{maxWidth: 560, padding: "20px 0", width: "100%", display: "flex", justifyContent: "end"}}>
                                    <div style={{backgroundColor: "#2f2f2f", padding: "10px 20px", borderRadius: 20, whiteSpace: 'pre-wrap'}}>
                                        {item.content}
                                    </div>
                                </div>
                            </div> :
                            <div key={item.id + index.toString()} style={{padding: "20px 0", display: "flex", gap: 20}}>
                                <div style={{width:32, height: 32, borderRadius: "50%", border: "1px solid #444", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <ChatGPTIcon/>
                                </div>
                                <div style={{flex: 1, marginTop: -14, overflowX: "auto"}}>
                                    <ChatGPTResponse content={item.content} />
                                </div>

                            </div>
                    ))}
                    {msgList.length === 0 ?
                        <div style={{height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                            <div  className={loading ? "rotating-div" : null}>
                                <ChatGPTIconBig/>
                            </div>
                            <div style={{fontSize: 24, marginTop: 20}}>
                                {loading ? "Loading ..." : "Hi, how can I help ?"}
                            </div>
                        </div> :
                        null
                    }
                </div>
            </div>
        </>
    )

}
