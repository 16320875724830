import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {createOrder} from "../../config/api";
import {useRequest} from "ahooks";
import {Popover} from "antd";

export default ({model, setModel, clintWidth, payModal, setPayModal, setQuestion}) => {

    const list = [
        {
            title: "示例",
            icon:
                <svg stroke="currentColor" fill="none" strokeWidth="1.5" viewBox="0 0 24 24"
                     strokeLinecap="round"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="5"></circle>
                    <line x1="12" y1="1" x2="12" y2="3"></line>
                    <line x1="12" y1="21" x2="12" y2="23"></line>
                    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                    <line x1="1" y1="12" x2="3" y2="12"></line>
                    <line x1="21" y1="12" x2="23" y2="12"></line>
                    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                </svg>,
            content: [
                "\"用简单的术语解释量子计算\" →",
                "\"给10岁孩子过生日有什么创意吗?\" →",
                "\"如何用Javascript发出HTTP请求?\" →",
            ]
        },
        {
            title: "功能",
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                     stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round"
                          d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"></path>
                </svg>,
            content: [
                "记住用户之前说的话",
                "允许用户提供后续更正",
                "接受过拒绝不适当要求的训练",
            ]
        },
        {
            title: "限制",
            icon:
                <svg stroke="currentColor" fill="none" strokeWidth="1.5" viewBox="0 0 24 24"
                     strokeLinecap="round"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                    <line x1="12" y1="9" x2="12" y2="13"></line>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>,
            content: [
                "可能偶尔产生不正确的信息",
                "可能偶尔会产生有害或有偏见的内容",
                "对2021年后的世界和事件了解有限",
            ]
        }
    ]

    const {userInfo} = useSelector(state => state.user);

    const {loading: createOrderLoading, runAsync: doCreateOrder} = useRequest(createOrder, {manual: true});

    // useEffect(() => pay(), [])
    const pay = () => {
        let mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        doCreateOrder({
            // alipay | wxpay
            type: "wxpay",
            amount: "200.00",
            // "amount": "0.01",
            // pc | mobile | qq | wechat | alipay
            device: mobile ? "mobile" : "pc"
        }).then(r => {
            window.location.href=r
            // window.open(r)
        })
    }

    return (
        <>
            <div style={{margin: "0 30px", display: "block"}}>
                <div style={{width: "100%", maxWidth: 800, margin: "30px auto 0", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div className="button" style={{display: "flex", gap: 5, alignItems: "center"}}>
                        <div className="model-select">
                            <div className="model-select-item" style={{marginLeft: model === "ChatGPT-4" ? 150 : 0}}></div>
                            <div style={{position: "absolute", inset: 0, display: "flex", color: "#8d8d9f", fontWeight: 500}}>
                                <div className="no-select" style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center", gap: 5, color: model === "ChatGPT-3.5" ? "#ececf1" : ""}} onClick={() => setModel("ChatGPT-3.5")}>
                                    <svg style={{color: model === "ChatGPT-3.5" ? "#19c27c" : ""}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" width="16" height="16">
                                        <path d="M9.586 1.526A.6.6 0 0 0 8.553 1l-6.8 7.6a.6.6 0 0 0 .447 1h5.258l-1.044 4.874A.6.6 0 0 0 7.447 15l6.8-7.6a.6.6 0 0 0-.447-1H8.542l1.044-4.874Z" fill="currentColor"></path>
                                    </svg>
                                    GPT-3.5
                                </div>
                                <div className="no-select" style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center", gap: 5, color: model === "ChatGPT-4" ? "#ececf1" : ""}} onClick={() => {
                                    if ([1].includes(userInfo.role)) {
                                        setPayModal(true)
                                    } else {
                                        setModel("ChatGPT-4")
                                    }
                                    }}>
                                    <svg style={{color: model === "ChatGPT-4" ? "#ab68ff" : ""}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" width="16" height="16">
                                        <path d="M12.784 1.442a.8.8 0 0 0-1.569 0l-.191.953a.8.8 0 0 1-.628.628l-.953.19a.8.8 0 0 0 0 1.57l.953.19a.8.8 0 0 1 .628.629l.19.953a.8.8 0 0 0 1.57 0l.19-.953a.8.8 0 0 1 .629-.628l.953-.19a.8.8 0 0 0 0-1.57l-.953-.19a.8.8 0 0 1-.628-.629l-.19-.953h-.002ZM5.559 4.546a.8.8 0 0 0-1.519 0l-.546 1.64a.8.8 0 0 1-.507.507l-1.64.546a.8.8 0 0 0 0 1.519l1.64.547a.8.8 0 0 1 .507.505l.546 1.641a.8.8 0 0 0 1.519 0l.546-1.64a.8.8 0 0 1 .506-.507l1.641-.546a.8.8 0 0 0 0-1.519l-1.64-.546a.8.8 0 0 1-.507-.506L5.56 4.546Zm5.6 6.4a.8.8 0 0 0-1.519 0l-.147.44a.8.8 0 0 1-.505.507l-.441.146a.8.8 0 0 0 0 1.519l.44.146a.8.8 0 0 1 .507.506l.146.441a.8.8 0 0 0 1.519 0l.147-.44a.8.8 0 0 1 .506-.507l.44-.146a.8.8 0 0 0 0-1.519l-.44-.147a.8.8 0 0 1-.507-.505l-.146-.441Z" fill="currentColor"></path>
                                    </svg>
                                    GPT-4
                                    <div style={{display: [1].includes(userInfo.role) ? "flex" : "none", width: 16, height: 16}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                             aria-hidden="true" strokeWidth="2"
                                             className="h-4 w-4 ml-0.5 h-4 w-4 transition-colors sm:ml-0 group-hover/options:text-gray-500 !text-gray-500 -ml-2 group-hover/button:text-brand-purple">
                                            <path fillRule="evenodd"
                                                  d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{fontSize: "2.25rem", fontWeight: 600, margin: clintWidth < 800 ? (clintWidth < 600 ? "30px 0 0px" : "60px 0 30px") : "90px 0 60px"}}>Chat-GPT</div>
                    <div style={{display: "flex", width: "100%", gap: 10, flexDirection: clintWidth < 800 ? "column" : "row"}}>
                        {list.map((item, index) =>
                            <div key={index} style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", gap: 10}}>
                                <div style={{display: "flex", flexDirection: clintWidth < 800 ? "row" : "column", alignItems: "center", marginTop: clintWidth < 800 ? 30 : 0, gap: 10}}>
                                    <div style={{width: 24, height: 24}}>
                                        {item.icon}
                                    </div>
                                    <div>{item.title}</div>
                                </div>
                                {item.content.map((content, index2) =>
                                    <div className="button no-select example-tips" key={index2} style={{width: clintWidth < 800 ? (clintWidth < 600 ? "100%" : 400) : "100%", textAlign: "center", padding: "10px 0", borderRadius: 5, transition: "0.3s"}} onClick={() => {
                                        if (index === 0) {
                                            setQuestion(content.slice(1, -3))
                                        }
                                    }}>{content}</div>
                                    // <div className="button no-select example-tips" key={index2} style={{width: clintWidth < 800 ? (clintWidth < 600 ? "100%" : 400) : "", textAlign: "center", padding: 10, borderRadius: 5, transition: "0.3s"}} onClick={() => {
                                    //     if (index === 0) {
                                    //         document.getElementById("input-area").value = content.substring(1, content.length - 3)
                                    //     }
                                    // }}>{content}</div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="slow-show" style={{position: "fixed", backgroundColor: "#0008", inset: 0, display: payModal ? "flex" : "none", alignItems: "center", justifyContent: "center", zIndex: 999}}>
                <div style={{maxWidth: 600, backgroundColor: "#202123", borderRadius: 5, border: "1px solid #40414F", color: "#fff", margin: 20}}>
                    <div style={{padding: "12px 16px", borderBottom: "1px solid #40414F", display: "flex", alignItems: "center"}}>
                        <div style={{flex: 1, fontSize: "1rem", fontWeight: 600}}>Your Plan</div>
                        <div className="button" style={{width: 18, height: 18}} onClick={() => setPayModal(false)}>
                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24"
                                 strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </div>
                    </div>
                    <div className="pay-card" style={{display: "flex"}}>
                        <div className="pay-card-item1" style={{padding: 16, flex: 1, display: "flex", flexDirection: "column", gap: "0.75rem"}}>
                            <div style={{fontSize: "1.25rem", fontWeight: 600, lineHeight: "1.75rem"}}>Free plan</div>
                            <div className="button no-select" style={{padding: 12, backgroundColor: "#8d8d9f", textAlign: "center", borderRadius: 5}}>Your current plan</div>
                            <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}><DetailIcon color="#8d8d9f"/>Access to our GPT-3.5 model</div>
                            <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}><DetailIcon color="#8d8d9f"/>Standard response speed</div>
                            <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}><DetailIcon color="#8d8d9f"/>Regular model updates</div>
                        </div>
                        <div style={{padding: 16, flex: 1, display: "flex", flexDirection: "column", gap: "0.75rem"}}>
                            <div style={{fontSize: "1.25rem", fontWeight: 600, lineHeight: "1.75rem", display: "flex"}}>
                                <div style={{flex: 1}}>ChatGPT Plus</div>
                                {/*<div style={{color: "#8d8d9f"}}>USD $20/mo</div>*/}
                                <div style={{color: "#8d8d9f"}}>CNY ￥200</div>
                            </div>
                            <div className="button no-select" style={{padding: 12, backgroundColor: createOrderLoading ? "rgba(16,162,126,0.5)" : "#10a27e", textAlign: "center", borderRadius: 5, transition: "0.3s"}} onClick={pay}>{createOrderLoading ? "Loading..." : "Upgrade to Plus"}</div>
                            <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}><DetailIcon color="#10a27e"/><div style={{flex: 1}}>10,000,000 Token quota available</div></div>
                            <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}><DetailIcon color="#10a27e"/><div style={{flex: 1}}>Access to GPT-4, our most capable model</div></div>
                            <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}><DetailIcon color="#10a27e"/><div style={{flex: 1}}>Faster response speed</div></div>
                            <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}><DetailIcon color="#10a27e"/><div style={{flex: 1}}>Exclusive access to beta features like Browsing, Plugins, and Code Interpreter</div></div>
                            <div>
                                <Popover content={<img alt="" src="https://10kv.oss-cn-shanghai.aliyuncs.com/qr-image.jpg" width={240}/>} placement="topLeft">
                                    <div style={{textDecoration: "underline"}}>I need help with a billing issue</div>
                                </Popover>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const DetailIcon = ({color}) => {

    return (
        <>
            <div style={{width: 20, height: 20, color}}>
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round"
                     strokeLinejoin="round" className="h-4 w-4 h-5 w-5 text-gray-400"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg>
            </div>
        </>
    )
}
