import {CloudUploadOutlined, InboxOutlined, InsertRowAboveOutlined} from "@ant-design/icons";

const Content = {
    title: "AIPal",
    company: "2021-2023 10K Volts Co.",
    icp: "沪ICP2023002085号",
    logoUrl: "https://ebai.oss-cn-shanghai.aliyuncs.com/logo%2Btext%26black.png",
    subTitle: "新一代认知智能大模型",

    primaryColor: "#404693",
    // primaryColor: "#af8af6",
    primaryColor_0_1: "rgb(253,243,228)",
    primaryColor_0_2: "rgb(253,233,203)",
    primaryColor_0_3: "rgb(253,222,178)",
    primaryColor_0_4: "rgb(253,212,153)",
    primaryColor_0_5: "rgb(253,202,127)",
    primaryColor_0_6: "rgb(253,192,102)",
    primaryColor_0_7: "rgb(253,182,76)",
    primaryColor_0_8: "rgb(253,172,52)",
    primaryColor_0_9: "rgb(253,162,26)",

    darkColor: "rgb(160,160,255)",
    darkColor_0_1: "rgb(253,243,228)",
    darkColor_0_2: "rgb(253,233,203)",
    darkColor_0_3: "rgb(253,222,178)",
    darkColor_0_4: "rgb(253,212,153)",
    darkColor_0_5: "rgb(253,202,127)",
    darkColor_0_6: "rgb(253,192,102)",
    darkColor_0_7: "rgb(253,182,76)",
    darkColor_0_8: "rgb(253,172,52)",
    darkColor_0_9: "rgb(253,162,26)",

    fileServiceUrl: "http://192.168.1.36:9000",

    menuList: [
        {
            key: 1,
            label: "企业云盘",
            path: "/netdisk",
            icon: <CloudUploadOutlined />
        },
        {
            key: 2,
            label: "两两堂训-签到记录",
            path: "/leanon/clock",
            icon: <InsertRowAboveOutlined />
        },
        {
            key: 3,
            label: "两两堂训-反馈问卷",
            path: "/leanon/feedback",
            icon: <InboxOutlined />
        },
    ]
}

export default Content;
