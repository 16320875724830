import {Button, Checkbox, ConfigProvider, Input, message, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {GithubFilled, LoadingOutlined, MailOutlined, MobileOutlined} from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {doCheck, doLogin, sendMobileCaptcha} from "../../config/api";
import {useRequest} from "ahooks";
import {ChatGPTIcon, ChatGPTIconBig, GithubIcon, GoogleIcon} from "./icon";

export default ({showLogin, setShowLogin}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("X-Access-Token") === null) {
            setShowLogin(true)
        } else {
            doCheck(dispatch).then(r => {
                setShowLogin(!r)
            })
        }
    }, [])

    const [mobile, setMobile] = useState("")
    const [mobileTrue, setMobileTrue] = useState(true)
    const [verifyCode, setVerifyCode] = useState("")
    const [verifyCodeTrue, setVerifyCodeTrue] = useState(true)
    const [captchaButtonText, setCaptchaButtonText] = useState("Send")
    const [intervalId, setIntervalId] = useState()

    const {loading: sendMobileCaptchaLoading, runAsync: runSendMobileCaptcha} = useRequest(sendMobileCaptcha, {manual: true});
    const {loading: loginLoading, runAsync: runLogin} = useRequest(doLogin, {manual: true});

    const inputMobile = (e) => {
        if (e.target.value.length <= 11 && (e.target.value.length === 0 || (/^1/.test(e.target.value) && /^[0-9]*$/.test(e.target.value)))) {
            setMobileTrue(true)
            setMobile(e.target.value)
        }
        if (e.target.value.length === 11) {
            getVerifyCode(e.target.value)
            document.getElementById("captcha-input").focus()
        }
    }

    const getVerifyCode = (value) => {
        clearInterval(intervalId)
        if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)) {
            runSendMobileCaptcha({countryCode: "86", mobile: value})
                .then(r => {
                    if (r?.result) {
                        message.success("Send Success").then()
                        let count = 30
                        setCaptchaButtonText(count + "s")
                        let interval = setInterval(() => {
                            count --
                            setCaptchaButtonText(count + "s")
                            if (count < 1) {
                                clearInterval(interval)
                                setCaptchaButtonText("Send")
                            }
                        }, 1000)
                        setIntervalId(interval)
                    } else {
                        message.error("Server Error").then()
                    }
                })
        } else {
            setMobileTrue(false)
        }
    }

    const inputVerifyCode = (e) => {
        if (e.target.value.length <= 4 && (e.target.value.length === 0 || /^[0-9]*$/.test(e.target.value))) {
            setVerifyCodeTrue(true)
            setVerifyCode(e.target.value)
        }
        if (e.target.value.length === 4) {
            login(e.target.value)
        }
    }
    const login = (value) => {
        let ok = true
        if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(mobile)) {
            setMobileTrue(false)
            ok = false
        }
        if (!/^\d{4}$/.test(value)) {
            setVerifyCodeTrue(false)
            ok = false
        }
        if (ok) {
            runLogin({mobile, verifyCode: value, loginType: "mobile", key: ""}, dispatch)
                .then(r => {
                    if (r) {
                        message.success("Login Success").then()
                        setShowLogin(false)
                    } else {
                        message.error("Captcha Error").then()
                        setVerifyCode("")
                        setVerifyCodeTrue(false)
                    }
                })
        }
    };

    return (
        <Modal open={showLogin} centered={true} footer={null} onCancel={() => setShowLogin(false)} width={400}>
            <div>
                <div className="login-modal">
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 10}}>
                        <div style={{padding: 10}}>
                            {/*<img src="https://ebai.oss-cn-shanghai.aliyuncs.com/aipal-logo-white.png" alt="" height="50"/>*/}
                            <div className="logo-shadow" style={{paddingBottom: 2}}>
                                <ChatGPTIconBig />
                            </div>
                        </div>
                        <div style={{fontSize: 20, fontWeight: 600, userSelect: "none"}}>Welcome ChatGPT</div>
                        <div style={{fontSize: 12, userSelect: "none"}}>Please enter your details to sign in.</div>
                        <div style={{display: "flex", width: "100%", textAlign: "center", gap: 10}}>
                            <div style={{userSelect: "none", cursor: "pointer", flex: 1, border: "1px solid #aaa", padding: 5, borderRadius: 5, alignItems: "center", display: "flex", justifyContent: "center", gap: 5}} onClick={() => message.info("Coming soon")}>
                                <GoogleIcon/>
                                Google
                            </div>
                            <div style={{userSelect: "none", cursor: "pointer", flex: 1, border: "1px solid #aaa", padding: 5, borderRadius: 5, alignItems: "center", display: "flex", justifyContent: "center", gap: 5}} onClick={() => message.info("Coming soon")}>
                                {/*<GithubFilled style={{fontSize: 18}}/>*/}
                                <GithubIcon/>
                                Github
                            </div>
                        </div>
                        <div style={{userSelect: "none", display: "flex", width: "100%", alignItems: "center", gap: 10, color: "#666"}}>
                            <div style={{flex:1, borderBottom: "1px solid #666"}}></div>
                            <div>or</div>
                            <div style={{flex:1, borderBottom: "1px solid #666"}}></div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", width: "100%", gap: 10}}>
                            <div>
                                <div style={{userSelect: "none"}}>Mobile</div>
                                <Input bordered={false} style={{border: "1px solid #aaa", marginTop: 5, color: "#fff"}} placeholder="Enter your mobile" value={mobile} onChange={inputMobile}/>
                                {mobileTrue ? null : <div style={{userSelect: "none", fontSize: 12, color: "#f00"}}>Enter correct mobile number</div>}
                            </div>
                            <div>
                                <div style={{userSelect: "none"}}>Verify code</div>
                                <div style={{display: "flex", alignItems: "center", gap: 10}}>
                                    <Input id="captcha-input" bordered={false} style={{border: "1px solid #aaa", marginTop: 5, color: "#fff"}} placeholder="Enter your verify code" value={verifyCode} onChange={inputVerifyCode}/>
                                    <Button onClick={() => getVerifyCode(mobile)} ghost style={{marginTop: 5}}>
                                        {/*{sendMobileCaptchaLoading ? <><LoadingOutlined /> 发送中</> : captchaButtonText}*/}
                                        {/*<div><LoadingOutlined /> 发送中</div>*/}
                                        <div>{captchaButtonText}</div>
                                    </Button>
                                </div>
                                {verifyCodeTrue ? null : <div style={{userSelect: "none", fontSize: 12, color: "#f00"}}>Enter correct verify code</div>}
                            </div>
                            <div style={{userSelect: "none", display: "flex", alignItems: "center", gap: 5}}>
                                <Checkbox defaultChecked={true}/>
                                Remember for 30 days
                            </div>
                        </div>
                        <Button style={{backgroundColor: "#f7f7f7", fontWeight: 600}} block loading={loginLoading} onClick={() => login(verifyCode)}>Sign in</Button>
                        <div style={{userSelect: "none", fontSize: 12}}>No account? We'll create one for you automatically.</div>
                    </div>

                </div>
            </div>
        </Modal>
    )
}