import {Provider, useDispatch} from "react-redux";
import {BrowserRouter, useRoutes} from "react-router-dom";
import index from "./store";
import {createRoot} from 'react-dom/client';

import {routes} from "./config/routes";
import {useEffect, useLayoutEffect} from "react";
import {initAxios} from "./config/api";
import {ConfigProvider, App, message} from "antd";
import zhCN from 'antd/locale/zh_CN';
import "./index.css"
import Content from "./config/content";
const Page = () => {
    const dispatch = useDispatch();
    document.title = "10KV-GPT"
    useLayoutEffect(() => {
        initAxios(dispatch);
        if (CSS.supports("flex:1")) {

        } else {
            message.warning("当前浏览器版本过低，建议升级后使用", 10000).then()
        }
    }, [dispatch]);
    return useRoutes(routes)
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={index}>
        <BrowserRouter>
            <ConfigProvider theme={{token: {colorPrimary: Content.primaryColor, fontSize: 14}}} locale={zhCN}>
                <App>
                    <Page/>
                </App>
            </ConfigProvider>
        </BrowserRouter>
    </Provider>
)
