export default ({miniSize, setMiniSize, clintWidth}) => {

    return (
            clintWidth > 920 ?
            // <div className="sider-botton button" style={{position: "fixed", borderRadius: 5, border: "1px solid #565869", width: 43, height: 43, left: 8, top: 68, zIndex: 10, display: (clintWidth < 1200 || miniSize) ? "flex" : "none", alignItems: "center", justifyContent: "center"}} onClick={() => setMiniSize()}>
            <div className="sider-botton button" style={{position: "fixed", borderRadius: 5, border: "1px solid #565869", width: 43, height: 43, left: 8, top: 12, zIndex: 10, display: (clintWidth < 920 || miniSize) ? "flex" : "none", alignItems: "center", justifyContent: "center"}} onClick={() => setMiniSize()}>
                <div style={{width: 16, height: 16}}>
                    <svg stroke="currentColor" fill="none"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                        <line x1="9" y1="3" x2="9" y2="21"></line>
                    </svg>
                </div>
            </div> :
            <div className="sider-botton button" style={{position: "fixed", left: 10, top: 18, zIndex: 10}} onClick={() => setMiniSize()}>
                <div style={{width: 24, height: 24}}>
                    <svg stroke="currentColor" fill="none"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                        <line x1="9" y1="3" x2="9" y2="21"></line>
                    </svg>
                </div>
            </div>
    )
}
