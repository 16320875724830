import React, {useState} from "react";
import {Input, message} from "antd";
import {CameraFilled} from "@ant-design/icons";
import {SendIcon, StopIcon, SubmittingIcon, UploadIcon} from "./icon";

export default ({submitQuestion, question, setQuestion, submitting, answering}) => {

    const handleKeyDown = (e) => {
        // Detect if Ctrl (Windows/Linux) or Meta (Mac) key is pressed along with Enter
        if (e.key === 'Enter') {
            if (e.ctrlKey || e.metaKey) {
                // Ctrl + Enter or Cmd + Enter -> Enter newline
                setQuestion((prev) => prev + '\n');
            } else {
                // Just Enter -> Submit
                e.preventDefault();  // Prevent the default Enter key behavior
                handleSubmit();
            }
        }
    };

    const handleChange = (e) => {
        setQuestion(e.target.value);
    };

    const handleSubmit = () => {
        submitQuestion(question)
    };

    return (
        <>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "0 40px"}}>
                <div style={{
                    width: "100%", maxWidth: 760, padding: "10px 20px",
                    backgroundColor: "#2f2f2f",
                    borderRadius: 20,
                    display: "flex",
                    gap: 10,
                    alignItems: "end",

                }}>
                    <div style={{width: 20, height: 20, padding: "6px 0"}}>
                        <UploadIcon/>
                    </div>
                    <Input.TextArea
                        style={{width: "100%", color: "#fff", fontSize: 16}}
                        autoSize={{minRows: 1, maxRows: 12}}
                        placeholder="Message ChatGPT"
                        bordered={false}

                        value={question}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                    <div>
                        {submitting ?
                            <SubmittingIcon/> :
                            answering ?
                                <div onClick={() => localStorage.setItem("answering", "stop")}><StopIcon/></div> :
                                <div onClick={handleSubmit}><SendIcon/></div>
                        }
                    </div>
                </div>
                <div style={{padding: 10, color: "#b4b4b4", userSelect: "none"}}>ChatGPT can make mistakes. Check important info.</div>
            </div>
        </>
    )

}
