import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {message, notification, Tag, Tooltip} from "antd";
import {
    CopyOutlined, DeleteOutlined,
    DoubleLeftOutlined, DownOutlined, HistoryOutlined,
    LogoutOutlined,
    MailOutlined,
    QuestionCircleOutlined, SettingOutlined, UserOutlined
} from "@ant-design/icons";
import {doGetQuota, doLogout} from "../../config/api";
import {useNavigate} from "react-router-dom";

export default ({setPayModal, deleteAll, quota}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {userInfo} = useSelector(state => state.user);
    const [showProgress, setShowProgress] = useState(false);

    // const [quota, setQuota] = useState({
    //     chat: 130,
    //     totalChat: 100,
    //     shareChat: 0,
    //     dailyChat: 0,
    //     image: 20,
    //     totalImage: 30,
    // })

    const logout = () => {
        doLogout(dispatch).then()
        navigate("/login")
    }

    const showChange = () => {
        setShowProgress(!showProgress)
    }

    const copyCode = (code) => {
        console.log(code)
        let tmpInput = document.createElement("textarea");
        document.body.appendChild(tmpInput);
        tmpInput.value = code
        tmpInput.select();
        document.execCommand("copy");
        tmpInput.remove();
        message.success("复制成功").then()
    }

    const openNotification = () => {
        notification.open({
            message: 'SUGGESTIONS',
            description:
                <div>
                    <span>感谢您的建议，请通过以下电子邮件地址与我们联系。</span>
                    <div style={{display: "flex", height: '26px'}}>
                        {/*Email : <Paragraph copyable={{ tooltips: false }} style={{display:"inline-block"}}>aipal@proton.me</Paragraph>*/}
                        <div>Email :</div>
                        <div style={{
                            background: "#eee",
                            border: "1px solid #ddd",
                            padding: "0px 2px",
                            boxSizing: "border-box",
                            height: "24px",
                            borderRadius: 4,
                            margin: "0px 2px"
                        }}>aipal@proton.me
                        </div>
                        <div onClick={() => copyCode("aipal@proton.me")}><CopyOutlined
                            style={{color: "rgb(47,132,253)"}}/></div>
                    </div>
                </div>
        })

    };

    return (
        <>
            <div style={{
                padding: "13px 0 5px",
                borderTop: "1px solid #565869",
                display: "flex",
                flexDirection: "column",
                whiteSpace: "nowrap",
                fontSize: 16
            }}>

                {/*<div style={{display: "flex", alignItems: "center", gap: 10}}>*/}
                {/*    <div style={{backgroundColor: "#919aed", height: 30, width: 30, display: "flex", alignItems: "center", justifyContent: "center", color: "#fff"}}>XB</div>*/}
                {/*    <div style={{flex: 1, display: "flex", gap: 10}}>*/}
                {/*        {userInfo.nickName}*/}
                {/*        {userInfo.role === 0 ? <Tag color="black">Master</Tag> : null}*/}
                {/*        {userInfo.role === 2 ? <Tag color="gold">Plus</Tag> : null}*/}
                {/*    </div>*/}
                {/*    · · ·*/}
                {/*</div>*/}

                <div style={{
                    backgroundColor: "#121212",
                    borderRadius: 10,
                    padding: "15px",
                    display: "flex",
                    flexDirection: "column"
                }}
                     onClick={showChange}
                >
                    <div className="button" style={{display: "flex", alignItems: "center", gap: 10}}>
                        <div
                            style={{
                                backgroundColor: "#ffa65a",
                                height: 30,
                                width: 30,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#fff",
                                borderRadius: 5
                            }}
                        >
                            <UserOutlined style={{fontSize: 20}}/>
                        </div>
                        <div style={{flex: 1, fontSize: 16, fontWeight: 600}}>
                            {userInfo.nickName}
                        </div>
                        <div style={{fontWeight: 800, fontSize: 14}}>
                            {[0].includes(userInfo.role) ? <div style={{color: "#f2bbdc"}}>MASTER</div> : null}
                            {[1, 6].includes(userInfo.role) ? <div style={{color: "#888"}}>USER</div> : null}
                            {[2,4,5].includes(userInfo.role) ? <div style={{color: "#aa8dff"}}>VIP</div> : null}
                            {[3].includes(userInfo.role) ? <div style={{color: "#f2a36f"}}>PLUS</div> : null}
                        </div>
                        <DownOutlined style={{color: "#aaa", fontSize: 14}} rotate={!showProgress ? 180 : 0}/>
                    </div>

                    <div style={{
                        fontSize: 12,
                        lineHeight: "12px",
                        color: "#8f8f8f",
                        height: showProgress ? 85 : 0,
                        transition: "0.3s"
                    }}>
                        <div style={{paddingTop: 10}}>
                            <div className="slow-show d1" style={{
                                borderTop: "1px solid #565869",
                                paddingTop: 10,
                                display: showProgress ? "" : "none"
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{flex: 1}}>
                                        聊天额度
                                        {/*<Tooltip>*/}
                                        {/*    <QuestionCircleOutlined style={{marginLeft: 5, color: "gray"}}/>*/}
                                        {/*</Tooltip>*/}
                                    </div>
                                    <div>{[0,3].includes(userInfo.role) ? "∞" : null}</div>
                                    <div>{[1,2,4,5,6].includes(userInfo.role) ? ((quota.chat) + " / " + (quota.totalChat >= 1000000 ? quota.totalChat / 1000000 + "M" : quota.totalChat)) + " Tokens" : null}</div>
                                </div>
                                <div style={{padding: "5px 0 10px"}}>
                                    <div style={{height: 10, backgroundColor: "#8f8f8f", borderRadius: 5}}>
                                        <div style={{
                                            height: 10,
                                            backgroundColor: "#a0a0ff",
                                            borderRadius: 5,
                                            width: ([0,3].includes(userInfo.role) ? 100 : quota.totalChat === 0 ? 0 : (quota.chat) / quota.totalChat * 100) + "%",
                                            maxWidth: "100%",
                                            transition: "0.3s"
                                        }}></div>
                                    </div>
                                </div>
                                {/*<Progress percent={100} strokeColor="#a0a0ff" trailColor="#DDF" showInfo={false}/>*/}
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{flex: 1}}>绘图额度</div>
                                    <div>{userInfo.role === 0 ? "∞" : quota.image + " / " + quota.totalImage} Pics</div>
                                </div>
                                <div style={{paddingTop: 5}}>
                                    <div style={{height: 10, backgroundColor: "#8f8f8f", borderRadius: 5}}>
                                        <div style={{
                                            height: 10,
                                            backgroundColor: "#a0a0ff",
                                            borderRadius: 5,
                                            width: quota.totalImage === 0 ? 0 : (quota.image / quota.totalImage * 100) + "%",
                                            maxWidth: "100%",
                                            transition: "0.3s"
                                        }}></div>
                                    </div>
                                </div>
                                {/*<Progress percent={100} strokeColor="#a0a0ff" trailColor="#DDF" showInfo={false}/>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", gap: 5, marginTop: 5}}>
                    <div className="sider-bottom-button button"
                         style={{display: [1,2,4,5].includes(userInfo.role) ? "flex" : "none", alignItems: "center", gap: 10}}
                         onClick={() => setPayModal(true)}>
                        <div><DoubleLeftOutlined rotate={90}/></div>
                        升级为Plus会员
                        <Tag color="gold">New</Tag>
                    </div>
                    <div className="sider-bottom-button button" onClick={deleteAll}>
                        {/*<div><HistoryOutlined /></div>*/}
                        <div><DeleteOutlined/></div>
                        清空历史
                    </div>
                    {/*<div className="sider-bottom-button button">*/}
                    {/*    <div><SettingOutlined/></div>*/}
                    {/*    Setting*/}
                    {/*</div>*/}
                    <div className="sider-bottom-button button" onClick={openNotification}>
                        <div><MailOutlined/></div>
                        意见与建议
                    </div>
                    <div className="sider-bottom-button button" onClick={logout}>
                        <div><LogoutOutlined/></div>
                        退出登录
                    </div>
                </div>
            </div>
        </>
    )
}
