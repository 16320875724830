import React from "react";
import Result404 from "../pages/common/404"

import Layout from "../pages/common/layout"
import Home from "../pages/home"
import Login from "../pages/common/login"

import WorkSpace from "../pages/workSpace2"
import ChatGPT from "../pages/chatgpt"
import Payback from "../pages/payback"
import Dashboard from "../pages/dashboard";
import Test from "../pages/test/index3";
import Test2 from "../pages/test/index4";
import Admin from "../pages/admin";
import Wheel from "../pages/demo/wen/wheel";
import Random from "../pages/demo/wen/random";


// 路由表
export const routes = [
    {
        path: "/",
        element: <WorkSpace/>,
    },
    {
        path: "/new",
        element: <ChatGPT/>,
    },
    {
        path: "/chatgpt",
        element: <ChatGPT/>,
    },
    {
        path: "/shaozi",
        element: <WorkSpace/>,
    },
    // {
    //     path: "/dashboard",
    //     element: <Dashboard/>
    // },
    // {
    //     path: "/workSpace",
    //     children: [
    //         {
    //             index: true,
    //             element: <WorkSpace/>,
    //         },
    //     ]
    // },
    {
        path: "/login",
        element: <Login/>,
    },
    {
        path: "/login/shaozi",
        element: <Login/>,
    },
    {
        path: "/payback",
        element: <Payback/>,
    },
    // {
    //     path: "/test",
    //     element: <Test/>,
    // },
    // {
    //     path: "/test2",
    //     element: <Test2/>,
    // },
    // {
    //     path: "/graphic",
    //     element: <Test/>,
    // },
    {
        path: "/admin",
        element: <Admin/>,
    },
    {
        path: "/wheel",
        element: <Wheel/>,
    },
    {
        path: "/random",
        element: <Random/>,
    },
    {
        path: "*",
        element: <Result404/>,
    }
];