import {useEffect, useState} from 'react'
import {requestServiceLog} from "../../config/api";
import {DatePicker, Form, Select, Spin, Switch} from "antd";
import {LoadingOutlined, ReloadOutlined} from "@ant-design/icons";
import {useRequest} from "ahooks";
import dayjs from "dayjs";

export default () => {

    const [msgList, setMsgList] = useState([])
    const [autoRefresh, setAutoRefresh] = useState(30000)
    const {loading: loading, runAsync: request} = useRequest(requestServiceLog, {manual: true});
    const [intervalId, setIntervalId] = useState(0)
    const [date, setDate] = useState(dayjs(new Date()).format().slice(0, 10))

    useEffect(() => {
        getData(date)
        clearInterval(intervalId)
        let id = setInterval(() => {
            if (autoRefresh) {
                getData(date)
            }
        }, autoRefresh)
        setIntervalId(id)
    }, [autoRefresh, date])

    const getData = () => {
        request({value: date}).then(r => {
            if (autoRefresh) {
                setMsgList(r)
                setTimeout(() => {
                    let chatListDiv = document.getElementById('logList')
                    chatListDiv.scrollTop = 99999;
                }, 10)
            }
        })
    }

    const onChange = (_, dateString) => {
        setDate(dateString)
    }

    return (
        <div style={{height: "100vh", width: "100vw", display: "flex", flexDirection: "column", color: "white"}}>
            <div style={{padding: 20, display: "flex", gap: 20}}>
                <div style={{flex: 1, display: "flex", alignItems: "center", gap: 20}}>
                    <div style={{whiteSpace: "nowrap"}}>日期</div>
                    <DatePicker onChange={onChange} defaultValue={dayjs(new Date())}/>
                    {/*</div>*/}
                    {/*<div style={{ flex: 1, display: "flex", alignItems: "center"}}>*/}
                    <div style={{whiteSpace: "nowrap"}}>自动刷新</div>
                    <Select style={{width: 200}} options={[
                        {value: 0, label: '不自动刷新'},
                        {value: 2000, label: '2秒间隔'},
                        {value: 5000, label: '5秒间隔'},
                        {value: 10000, label: '10秒间隔'},
                        {value: 30000, label: '30秒间隔'},
                    ]} value={autoRefresh}
                            onChange={(e) => setAutoRefresh(e)}/>
                </div>
            </div>
            <div style={{padding: "0 20px"}}>
                <div style={{borderBottom: "1px solid white", width: "100%"}}/>
            </div>
            <div style={{display: "flex", padding: "10px 20px"}}>
                <div style={{flex: 1}}>最后更新时间：</div>
                <div>{new Date().toLocaleString()}</div>
                <ReloadOutlined style={{marginLeft: 5, cursor: "pointer"}} onClick={getData}/>
            </div>
            <div style={{
                flex: 1,
                margin: "0 20px 20px",
                padding: "10px 5px 20px 20px",
                backgroundColor: "black",
                overflow: "auto",
                borderRadius: 10,
                transition: "0.3s",
                opacity: loading ? 0.5 : 1
            }}>
                <div className="logList" id="logList"
                     style={{whiteSpace: "pre-wrap", overflow: "auto", height: "100%"}}>
                    {msgList.map((item, index) =>
                        <div key={index}>{item}</div>
                    )}
                </div>
                {loading && <LoadingOutlined style={{position: "absolute", top: "50%", left: "50%", fontSize: 40}}/>}
            </div>
        </div>
    )
}