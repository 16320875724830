import React, {useState} from "react";
import {ShareAltOutlined} from "@ant-design/icons";
import {notification} from "antd";

export default ({newChat, openDrawer, clintWidth}) => {

    const [api, contextHolder] = notification.useNotification();
    const [helpOpen, setHelpOpen] = useState(false)
    const openNotification = () => {
        if (!helpOpen) {
            setHelpOpen(true)
            api.open({
                message: '帮助文档',
                description:
                    <div style={{height: "40vh", minHeight: 400}}>
                        <div style={{border: "1px solid black", height: "100%", marginTop: 10, display: "flex", overflow: "auto"}}>
                            <div style={{borderRight: "1px solid gray", width: 100}}>Menu</div>
                            <div style={{flex: 1}}>Documents</div>
                        </div>
                    </div>,
                duration: 0,
                placement: "bottomRight",
                onClose: () => {
                    setHelpOpen(false)
                }
            });
        }
    };

    return (
        <div className="temp-head">
            <div style={{backgroundColor: "#202123",height: 60, display: "flex", alignItems: "center", padding: "0 10px"}}>
                <div style={{flex: 1, display: "flex"}}>
                    {/*<img src="https://ebai.oss-cn-shanghai.aliyuncs.com/10kv-logo-color.png" alt="" style={{height: 40, margin: "10px 0 0 50px"}}/>*/}
                    {/*<img src="https://ebai.oss-cn-shanghai.aliyuncs.com/logo%2Btext.png" alt="" style={{height: 30, marginLeft: clintWidth < 920 ? 34 : 0}}/>*/}
                </div>
                <div style={{display: "flex", gap: 10}}>
                    {/*<div onClick={openNotification}>HELP</div>*/}
                    {/*<ShareAltOutlined style={{fontSize: 20}}/>*/}
                </div>

            </div>
            <div className="wp-header">
                <div className="button" onClick={openDrawer}>
                    <div style={{height: 24, width: 24}}>
                        <svg stroke="currentColor" fill="none" strokeWidth="1.5" viewBox="0 0 24 24" strokeLinecap="round"
                             strokeLinejoin="round"
                             xmlns="http://www.w3.org/2000/svg">
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </div>
                </div>
                <div style={{flex: 1, textAlign: "center", fontSize: 20}}>ChatGPT</div>
                <div className="button" onClick={newChat}>
                    <div style={{height: 24, width: 24}}>
                        <svg stroke="currentColor" fill="none" strokeWidth="1.5" viewBox="0 0 24 24" strokeLinecap="round"
                             strokeLinejoin="round"
                             xmlns="http://www.w3.org/2000/svg">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                    </div>
                </div>
            </div>
            {contextHolder}
        </div>
    )
}
