import {scrollToBottom} from "./tools";
import {doChatRequest2, doGetRecord} from "../../config/api";
import {message} from "antd";


export const changeProject = (projectId, setProjectId, id, setMsgList, setShowHistory, scrollToBottom, setLoading) => {
    if (typeof (id) == "string") {

        if (id === projectId) return

        setProjectId(id)
        setMsgList([])
        setLoading(true)
        doGetRecord({projectId: id}).then(r => {

            // if (r.projectId !== projectId) return
            setMsgList(r.msgList)
            scrollToBottom()
            setLoading(false)
        })
    } else {
        setMsgList([])
    }
    setShowHistory(window.innerWidth > 500)

}


export const submitQuestion = (msgList, question, setMsgList, projectId, setProjectId, answerId, setAnswerId, setQuestion, scrollToBottom, submitting, setSubmit, setAnswering, model) => {

    if (question === "" || submitting) return
    setSubmit(true)
    let newMsgList = [
        ...msgList.map(item => ({
            role: item.role,
            content: item.content
        })),
        {
        role: "user",
        content: question
    }]
    // setMsgList(newMsgList)
    scrollToBottom()

    doChatRequest2({msgList: newMsgList, model, projectId})
        .then(r => {
            setSubmit(false)
            setAnswering(true)
            if (r.success) {
                setProjectId(r.projectId)
                setTimeout(() => {
                    setQuestion("")
                }, 100)
                localStorage.setItem("answering", r.recordId)
                let response = ""
                const es = new EventSource('/sse/connect?key=' + r.sseKey);
                es.onmessage = (event) => {
                    if (localStorage.getItem("answering") !== "" && localStorage.getItem("answering") !== r.recordId) {
                        es.close()
                        if (localStorage.getItem("answering") === "stop") {
                            localStorage.setItem("answering", "")
                        }
                        setAnswering(false)
                        return
                    }
                    let data = JSON.parse(event.data)
                    response += data.content
                    setMsgList([...r.msgList,
                        {
                            role: "assistant",
                            content: response
                        }
                    ])
                    if (data.role === "assistant") scrollToBottom("messageList")
                };

                es.onerror = (error) => {
                    // console.log("SSE Finish:", error);
                    localStorage.setItem("answering", "")
                    setAnswering(false)
                    es.close()
                };

            } else {
                message.error(r.errorMsg).then()
            }
        })

}