import React, {useEffect, useState} from 'react';

import "./random.css"
import {Button, Collapse, ColorPicker, Input, InputNumber} from "antd";
import {CaretDownOutlined, DeleteOutlined} from "@ant-design/icons";

const used = [
    {title: "单人", range: [1, 350]},
    {title: "双人", range: [261, 300]},
    {title: "撒娇", range: [1, 40]},
]

const Random = () => {

    const [color, setColor] = useState({r: 0, g: 0, b: 0});
    const [showNum, setShowNum] = useState(1);
    const [minNum, setMinNum] = useState(1);
    const [maxNum, setMaxNum] = useState(350);
    const [nextResult, setNextResult] = useState(-1);
    const [isSpinning, setIsSpinning] = useState(false);
    const [intervalId, setIntervalId] = useState(0);
    const [recent, setRecent] = useState([]);

    useEffect(() => {
        document.title = "摇号"
    }, [])

    const spin = () => {
        let smallNum = minNum
        let bigNum = maxNum

        if (smallNum > bigNum) {
            smallNum = maxNum
            bigNum = minNum
        }

        if (isSpinning) {
            clearInterval(intervalId)
            let finalResult = showNum
            if (nextResult !== -1) {
                finalResult = nextResult;
                setNextResult(-1)
            }
            setIsSpinning(false)
            setShowNum(finalResult)
        } else {
            setIsSpinning(true)

            setRecent([...recent, ])
            let id = setInterval(() => {
                setShowNum(Math.floor(Math.random() * (bigNum - smallNum) + smallNum))
            }, 0)
            setIntervalId(id)
        }
    }

    const calcBaseNumber = () => {
        return Math.floor(minNum / 10) * 10 + 1
    }

    return (
        <div>
            <div style={{position: "sticky", top: 0, zIndex: 999,  backgroundColor: "rgb(" + color.r + "," + color.g + "," + color.b + ")", borderBottom: "1px solid #fff"}}>
                <div style={{display: "flex", justifyContent: "right"}}>
                    <ColorPicker value={color} onChange={(value) => {
                        setColor(value.metaColor);
                    }}/>
                </div>
                <div style={{padding: "0px 40px 50px",display: "flex", justifyContent: "center", width: "calc(100% - 80px)", flexDirection: "column", alignItems: "center", gap: 10}}>

                    <div style={{color: "#fff", fontSize: 60, letterSpacing: 5,paddingLeft: 5, textAlign: "center"}}>
                        {showNum}
                    </div>

                    {/*<div style={{color: "#ccc",}}>*/}
                    {/*    {minNum} ~ {maxNum}*/}
                    {/*</div>*/}

                    <div>
                        <button style={{padding: "2px 18px"}} onClick={spin}>开始</button>
                        <button style={{padding: "2px 18px", marginLeft: 5}} onClick={spin}>暂停</button>
                    </div>
                    <div>
                        <input style={{width: 45}} value={minNum} onChange={(e) => setMinNum(e.target.value)}/>
                        <input style={{width: 45, marginLeft: 5}} value={maxNum} onChange={(e) => setMinNum(e.target.value)}/>
                    </div>
                </div>
            </div>

            <div style={{padding: 0}}>
                <Collapse defaultActiveKey={"1"} items={[
                    {
                        key: '1',
                        label: '配置',
                        children: <>
                            <div style={{display: "flex"}}>
                                <div style={{ flex: 1}}>
                                    <div>最大值</div>
                                    <InputNumber value={minNum} onChange={(e) => setMinNum(e)}></InputNumber>

                                </div>
                                <div style={{ flex: 1}}>
                                    <div>最小值</div>
                                    <InputNumber value={maxNum} onChange={(e) => setMaxNum(e)}></InputNumber>
                                </div>
                            </div>
                            <div>常用</div>
                            <div style={{display: "grid", gridGap: 20, gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))"}}>
                                {used.map(item =>
                                    <div
                                        style={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#fff", color: "#000", padding: 5, borderRadius: 5, cursor: "pointer" }}
                                        onClick={() => {
                                            setMinNum(item.range[0])
                                            setMaxNum(item.range[1])
                                        }}
                                    >
                                        <div>{item.title}</div>
                                        <div>{item.range[0]} ~ {item.range[1]}</div>
                                        <div></div>
                                    </div>
                                )}
                            </div>
                        </>
                        ,
                    },
                    {
                        key: '2',
                        label: '高级',
                        children: <>
                            <div style={{padding: 0}}>
                                {/*<div>内幕</div>*/}
                                <InputNumber style={{backgroundColor: nextResult < minNum || nextResult > maxNum ?"#ff9999" : ""}} value={nextResult} onChange={(e) => setNextResult(e)}></InputNumber>
                                <div style={{marginTop: 20, display: "flex", flexDirection: "column", gap: 10}}>
                                    {
                                        Array.from({ length: Math.ceil(maxNum / 10) - Math.floor(minNum / 10)}, (_, i) => (
                                            <div style={{marginBottom: i % 10 === 9 ? 20 : 0, display: "grid", gap: 10, gridTemplateColumns: "repeat(10, 1fr)"}}>
                                                {
                                                    Array.from({ length: 10 }, (_, j) => (
                                                        // <div style={{flex: 1, textAlign: "center"}} key={i * 10 + j}>{i * 10 + j}</div>
                                                        <div style={{color: "#000", cursor: "pointer", backgroundColor: nextResult === calcBaseNumber() + i * 10 + j ? "#d0cdff" : (calcBaseNumber() + i * 10 + j >= minNum && calcBaseNumber() + i * 10 + j <= maxNum ? "#f0f0f0" : "#888"), padding: 5, borderRadius: 5, display: "flex" ,alignItems: "center", justifyContent: "center", flex: 1}} onClick={() => setNextResult(calcBaseNumber() + i * 10 + j)}>
                                                            {calcBaseNumber() + i * 10 + j}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                                <div>背景颜色</div>
                                <ColorPicker value={color} onChange={(value) => {
                                    setColor(value.metaColor);
                                }}/>
                            </div>
                        </>
                        ,
                    },
                ]} />
            </div>
        </div>
    );
};

export default Random;