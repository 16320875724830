import axios from "axios";
import {setUserInfo} from "../store/userSlice";

export const doLogin = async (body, dispatch) => {
    let resp = true
    await request("user/login", body).then(r => {
        if (r?.result) {
            setToken(r.token)
            dispatch(setUserInfo(r.userInfo))
        } else {
            setToken("")
            resp = false
        }
    })
    return resp
};

export const doCheck = async (dispatch) => {
    let resp = true
    await request("user/check").then(r => {
        if (r?.result) {
            dispatch(setUserInfo(r.userInfo))
            setToken(r.token)
        } else {
            setToken("")
            resp = false
        }
    })
    return resp
}
export const doLogout = async (dispatch) => {
    await request("user/logout").then(() => {
        setToken("")
        dispatch(setUserInfo({}))
    })
};

export const sendMobileCaptcha = async body => request("user/sendMobileCaptcha", body);
export const doChatRequest = async body => request("chat/request", body);
export const doChatRequest2 = async body => request("chat/request2", body);
export const doGetRecord = async body => request("chat/getRecord", body);
export const doGetQuota = async body => request("user/getQuota", body);
export const doGetHistory = async body => request("chat/history", body);
export const doDeleteHistory = async body => request("chat/deleteHistory", body);
export const doDeleteMsg = async body => request("chat/delete", body);
export const createOrder = async body => request("payment/createOrder", body);
export const checkOrder = async body => request("payment/checkOrder", body);

export const requestText2Image = async body => request("image/sd/request/text2image", body);
export const requestImage2Image = async body => request("image/sd/request/image2image", body);
export const requestImage= async body => request("image/simpleRequest", body);
export const requestServiceLog = async body => request("static/log", body);

export const initAxios = dispatch => {
    axios.defaults.validateStatus = status => {
        [400, 401, 500, 504].includes(status) && dispatch(setUserInfo({}))
        return true
    }
}
let token = localStorage.getItem("X-Access-Token") === null ? "" : localStorage.getItem("X-Access-Token")

export const setToken = (newToken) => {
    token = newToken
    localStorage.setItem("X-Access-Token", newToken)
}
const request = async (url, body) => {

    return (await axios.post("/api/" + url, body,
        {
            headers: {
                "X-Access-Token": token
            }
        })).data.object
}

const getRequest = async (url) => {

    return (await axios.get("/api/" + url,
        {
            headers: {
                "X-Access-Token": token
            }
        })).data.object
}
