import React, {useEffect, useState, useRef} from "react";
import {Button, Image, Input, message, Modal, Upload} from "antd";
import {useRequest} from "ahooks";
import {doChatRequest, doDeleteMsg} from "../../config/api";
import {
    CameraFilled,
    FileOutlined,
    FolderOutlined,
    InboxOutlined,
    FileImageFilled,
    CloseOutlined, EyeOutlined, DeleteOutlined
} from '@ant-design/icons'
import Content from "../../config/content";

export default ({
                    userInfo,
                    msgList,
                    setMsgList,
                    model,
                    temperature,
                    top,
                    listMode,
                    saveMode,
                    projectId,
                    setProjectId,
                    tempLoading,
                    setTempLoading,
                    setTempResponse,
                    clintWidth,
    question, setQuestion, refreshQuota
                }) => {
    const {runAsync: request, cancel: cancelAsk} = useRequest(doChatRequest, {manual: true});
    const [lastScrollT, setLastScrollT] = useState(0)
    const [isScroll, setIsScroll] = useState(true)
    const [uploadDialog, setUploadDialog] = useState(false)
    const [imageURL, setImageURL] = useState("")
    const [previewVisible, setPreviewVisible] = useState(false)
    const isScrollRef = useRef(isScroll);
    // isScrollRef.current = isScroll
    useEffect(() => {
        isScrollRef.current = isScroll
    }, [isScroll])
    useEffect(() => {
        let msgListDom = document.getElementById("msgList")
        let { scrollTop, clientHeight, scrollHeight } = msgListDom
            const controlNavbar = () => {
                setLastScrollT(msgListDom.scrollTop);
                if (msgListDom.scrollTop < lastScrollT) {
                        setIsScroll(false)
                } else if (msgListDom.scrollTop > lastScrollT && scrollHeight - scrollTop - clientHeight < 200){
                        setIsScroll(true)
                }
            };
        msgListDom.addEventListener("scroll", controlNavbar);
            return () => {
                msgListDom.removeEventListener("scroll", controlNavbar);
            };
        }, [lastScrollT]);
    const inputQuestion = (e) => {
        if (e.target.value !== "\n") {
            setQuestion(e.target.value)
        }
    }

    const scrollToBottom = () => {
        if(isScrollRef.current) {
            document.getElementById("msgList").scrollTop = document.getElementById("msgList").scrollHeight;
        }
    }

    const submit = (e) => {
        setIsScroll(true)
        if (e.keyCode === 229) {
            return
        }
        if (!question && imageURL === "") {
            message.warning("请输入问题").then()
            return
        }
        if (tempLoading) {
            message.info("上一条任务正在计算中").then()
            return
        }

        setQuestion("")
        setTempResponse("")
        setTempLoading(true)

        let newList = [...msgList,
            {
                role: "user",
                time: new Date(),
                content: question,
                imageURL: imageURL !== "" ? imageURL : null
            },
        ].filter(m => m.role !== "error")
        setMsgList(newList)
        scrollToBottom()
        submitRequest(newList)
        setImageURL("")
    }
    const regenerate = () => {
        setIsScroll(true)
        if (tempLoading) {
            message.info("上一条任务正在计算中").then()
            return
        }
        let newList = [...msgList].filter(m => m.role !== "error");
        doDeleteMsg({idList: [newList.pop().id]}).then()
        setMsgList(newList)
        setTempResponse("")
        setTempLoading(true)
        submitRequest(newList)
    }

    const submitRequest = (newList) => {
        request({msgList: newList, projectId, model, temperature, top, listMode, saveMode})
            .then(r => {
                if (r.success) {
                    // setMsgList(r.msgList)
                    // setProjectId(r.projectId)
                    let source = new EventSource('/sse/connect?key=' + r.msgList[r.msgList.length - 1].content);
                    let temp = ""
                    //监听服务器端发来的事件：message
                    source.onmessage = (e) => {
                        // if (e.data.startsWith("**done**")) {
                        //     temp = e.data.slice(8)
                        //     return
                        // }
                        temp += e.data
                            .replaceAll("**n**", "\n")
                            .replaceAll("**r**", "\r")
                            .replaceAll("** **", " ")
                            .replaceAll("**done**", "")
                        // console.log(e)
                        // console.log(Date.now() + ": " +temp)
                        setTempResponse(temp)
                        scrollToBottom()
                    }

                    //监听服务器端发来的事件：error
                    source.onerror = () => {
                        // console.log(temp)
                        newList.push({
                            role: "assistant",
                            id : r.msgList[r.msgList.length - 1].content,
                            time: new Date(),
                            content: temp,
                            // feedback: "like"
                        })
                        setMsgList(newList)
                        setProjectId(r.projectId)
                        refreshQuota()
                        setTempLoading(false)
                        source.close()
                    };

                } else {
                    message.error("账号额度不足").then()
                    setMsgList(r.msgList)
                    setTempLoading(false)
                }
                scrollToBottom()
            })
    }

    return (
        <div style={{display: "flex",flexDirection: "column"}}>
            <div className="input-div">
                <div style={{margin: "0 auto", maxWidth: 800}}>
                    {imageURL !== "" && <div style={{marginLeft: 24, marginBottom: 10}}>
                        <Image
                            width={150}
                            src={imageURL}
                            preview={{
                                mask: (
                                    <div style={{display: "flex", gap: 20}}>
                                        <div onClick={() => setPreviewVisible(true)}><EyeOutlined /> 预览</div>
                                        <div onClick={() => setImageURL("")} ><DeleteOutlined /> 删除</div>
                                    </div>
                                ),
                                visible: previewVisible,
                                closeIcon: <CloseOutlined onClick={() => setPreviewVisible(false)}/>
                            }}
                        />
                    </div> }
                        {/*// <div  className="user-image" onClick={() => setImageURL("")}  style={{marginLeft: 22, display: "inline"}}><img  className="img-upload" src={imageURL} alt="" style={{width: "20%", height: "20%",borderRadius: 20 }}/></div>}*/}
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "0 20px",
                gap: 10
            }}>
                <div style={{
                    padding: "8px 12px",
                    border: "1px solid #565869",
                    borderRadius: 5,
                    backgroundColor: "#343541",
                    color: "#d9d9e3",
                    display: (projectId === null || clintWidth < 800) ? "none" : "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    gap: 5
                }}
                     onClick={regenerate}>
                    <svg stroke="currentColor" fill="none" viewBox="0 0 24 24"
                         className="h-3 w-3 flex-shrink-0 button"
                         height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"
                    >
                        <polyline points="1 4 1 10 7 10"></polyline>
                        <polyline points="23 20 23 14 17 14"></polyline>
                        <path
                            d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                    </svg>
                    重 新 回 答
                </div>

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    width: "90%",
                    justifyContent: "center",
                }}>
                    <div style={{
                        flex: 1,
                        maxWidth: 1200,
                        color: "#6a6c7a",
                        backgroundColor: "#40414f",
                        boxShadow: "0 0 15px rgba(0,0,0,0.1)",
                        borderRadius: 10,
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <div className="input-text"
                             style={{display: "flex", width: "100%", alignItems: "center", gap: 10}}>
                            <div style={{flex: 1}}>
                                <Input.TextArea
                                    id="input-area"
                                    // classname="input-box"
                                    // style={{resize: 'none', width: "100%", backgroundColor: "#3c3c3c", color: "#fff"}}
                                    style={{resize: 'none', width: "100%", color: "#fff", fontSize: 18}}
                                    autoSize={{minRows: 1, maxRows: 6}}
                                    placeholder="在此输入你的问题"
                                    bordered={false}
                                    value={question}
                                    onChange={inputQuestion}
                                    onPressEnter={submit}
                                />
                            </div>
                            {
                                model === "ChatGPT-3.5" ? null : [3].includes(userInfo.role) ? <div className="button" style={{fontSize: 18}} onClick={() => setUploadDialog(true)}><CameraFilled /></div> : null                           }
                            <div style={{width: 16, height: 16}} onClick={submit} className="button">
                               <div>
                                   <svg  style={{width: 20,height: 20}} >
                                       <path
                                           d="M.5 1.163A1 1 0 0 1 1.97.28l12.868 6.837a1 1 0 0 1 0 1.766L1.969 15.72A1 1 0 0 1 .5 14.836V10.33a1 1 0 0 1 .816-.983L8.5 8 1.316 6.653A1 1 0 0 1 .5 5.67V1.163Z"
                                           fill="currentColor"></path>
                                   </svg>
                               </div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        width: 16,
                        height: 16,
                        display: (clintWidth < 800 && projectId !== null) ? "flex" : "none"
                    }}>
                        <svg stroke="currentColor" fill="none" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <polyline points="1 4 1 10 7 10"></polyline>
                            <polyline points="23 20 23 14 17 14"></polyline>
                            <path
                                d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                        </svg>
                    </div>
                </div>
                <Modal title="上传" open={uploadDialog} footer={null}
                       onCancel={() => {
                           setUploadDialog(false)
                       }}
                       destroyOnClose={true}
                >
                    <Upload.Dragger
                        name="file"
                        maxCount={1}
                        beforeUpload={(file) => {
                            const typeArr = ["image/png","image/jpeg","image/webp","image/bmp"]
                            const isPNG = typeArr.includes(file.type)
                            if (!isPNG) {
                                message.error(`${file.name} is not a picture`);
                            }
                            return isPNG || Upload.LIST_IGNORE;
                        }}
                        style={{marginTop: 10}}
                        action="https://aiviz.cn/api/aiviz/playground/upload"
                        onChange={(e) => {
                            if (e?.file?.response !== undefined) {
                                setImageURL(e.file.response)
                                setUploadDialog(false)
                            } else {
                                // console.log(e)
                            }
                        }}
                    >
                        <p className="ant-upload-drag-icon">
                            <FileImageFilled />
                        </p >
                        <p className="ant-upload-text">点击或拖动文件至此处上传</p >
                        {/*<p className="ant-upload-hint">支持多文件上传，文件夹请使用上传文件夹</p >*/}
                    </Upload.Dragger>
                </Modal>
                <div style={{fontSize: "0.75rem", color: "#c5c5d2", textAlign: "center"}}>ChatGPT可能产生关于人、地点或事实的不准确信息。 <span
                        style={{textDecoration: "underline"}}>ChatGPT 7月20日版本</span></div>
            </div>
        </div>
    )

}
