import React, {useEffect, useState} from "react";
import {Checkbox, message, Input, ConfigProvider} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {doCheck, doDeleteHistory, doLogin, sendMobileCaptcha} from "../../config/api";
import {useDispatch} from "react-redux";
import {LoadingOutlined, MailOutlined, MobileOutlined} from "@ant-design/icons";
import {useRequest} from "ahooks";


const Login = () => {

    const [clintWidth, setClintWidth] = useState(window.innerWidth)
    const [clientHeight, setClientHeight] = useState(window.innerHeight)
    const location = useLocation();
    
    useEffect(() => {
        window.addEventListener("resize", () => {
            setClintWidth(window.innerWidth)
            setClientHeight(window.innerHeight)
        })
        setInterval(() => {
            setClintWidth(window.innerWidth)
            setClientHeight(window.innerHeight)
        }, 1000)
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("X-Access-Token") === null) {
            doCheck(dispatch).then(r => {
                if (r) {
                    navigate("/")
                }
            })
        }
    }, [])

    const [mobile, setMobile] = useState("")
    const [mobileTrue, setMobileTrue] = useState(true)
    const [verifyCode, setVerifyCode] = useState("")
    const [verifyCodeTrue, setVerifyCodeTrue] = useState(true)
    const [captchaButtonText, setCaptchaButtonText] = useState("获取验证码")
    const [intervalId, setIntervalId] = useState()

    const {loading: sendMobileCaptchaLoading, runAsync: runSendMobileCaptcha} = useRequest(sendMobileCaptcha, {manual: true});
    const {loading: loginLoading, runAsync: runLogin} = useRequest(doLogin, {manual: true});

    const inputMobile = (e) => {
        if (e.target.value.length <= 11 && (e.target.value.length === 0 || (/^1/.test(e.target.value) && /^[0-9]*$/.test(e.target.value)))) {
            setMobileTrue(true)
            setMobile(e.target.value)
        }
        if (e.target.value.length === 11) {
            getVerifyCode(e.target.value)
            document.getElementById("captcha-input").focus()
        }
    }

    const getVerifyCode = (value) => {
        clearInterval(intervalId)
        if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)) {
            runSendMobileCaptcha({countryCode: "86", mobile: value})
                .then(r => {
                    if (r?.result) {
                        message.success("发送成功").then()
                        let count = 30
                        setCaptchaButtonText(count + "秒后重试")
                        let interval = setInterval(() => {
                            count --
                            setCaptchaButtonText(count + "秒后重试")
                            if (count < 1) {
                                clearInterval(interval)
                                setCaptchaButtonText("获取验证码")
                            }
                        }, 1000)
                        setIntervalId(interval)
                    } else {
                        message.error("服务器未知错误").then()
                    }
                })
        } else {
            setMobileTrue(false)
        }
    }

    const inputVerifyCode = (e) => {
        if (e.target.value.length <= 4 && (e.target.value.length === 0 || /^[0-9]*$/.test(e.target.value))) {
            setVerifyCodeTrue(true)
            setVerifyCode(e.target.value)
        }
        if (e.target.value.length === 4) {
            login(e.target.value)
        }
    }
    const login = (value) => {
        let ok = true
        if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(mobile)) {
            setMobileTrue(false)
            ok = false
        }
        if (!/^\d{4}$/.test(value)) {
            setVerifyCodeTrue(false)
            ok = false
        }
        if (ok) {
            runLogin({mobile, verifyCode: value, loginType: "mobile", key: location.pathname.endsWith("shaozi") ? "shaozi" : ""}, dispatch)
                .then(r => {
                    if (r) {
                        message.success("登录成功").then()
                        navigate("/");
                    } else {
                        message.error("验证码错误").then()
                        setVerifyCode("")
                        setVerifyCodeTrue(false)
                    }
                })
        }
    };

    return (
        <div className="slow-show login-layout" style={{backgroundColor: "#343541", height: clientHeight, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <img className="logo-shadow" src="https://ebai.oss-cn-shanghai.aliyuncs.com/10kv-logo-color.png" alt="" height="60" style={{paddingBottom: 2, marginBottom: 30}}/>
            <div style={{padding: "0 20px"}}>
                <div className="login-card">
                    <div className="login-card-left">
                        <img src="https://ebai.oss-cn-shanghai.aliyuncs.com/aipal-logo-white.png" alt="" height="40" style={{margin: 20}}/>
                    </div>

                    <div className="login-card-right">
                        <div style={{flex: 1}}>
                            <div style={{marginTop: 20, fontSize: 24, fontWeight: 800, display: "flex", gap: 5}}>欢迎使用<div style={{color: "#444"}}>10KV-Tools</div></div>
                            <div style={{marginTop: 20}}>新一代认知智能大模型</div>
                            <div>数据与知识双轮驱动，让机器像人一样思考</div>

                            <div className="input-margin" style={{fontSize: 16, display: "flex", flexDirection: "column"}}>
                                <div style={{display: "flex", gap: 10}}>
                                    <MobileOutlined style={{fontSize: 18}}/>
                                    <Input className="login-input" placeholder="手机号" bordered={false} value={mobile} onChange={inputMobile}/>
                                </div>
                                <div style={{height: 20, fontSize: 12, marginLeft: 40, color: mobileTrue ? "#fff" : "#f00", transition: "0.3s"}}>请输入正确手机号</div>
                                <div style={{display: "flex", gap: 10}}>
                                    <MailOutlined />
                                    <Input id="captcha-input" className="login-input" placeholder="验证码" bordered={false} value={verifyCode} onChange={inputVerifyCode}/>
                                    <div className={"no-select captcha-button" + (captchaButtonText === "获取验证码" ? "" : " captcha-button-active")} onClick={() => getVerifyCode(mobile)}>
                                        {/*{sendMobileCaptchaLoading ? <><LoadingOutlined /> 发送中</> : captchaButtonText}*/}
                                        <div style={{position: "absolute", marginLeft: 15, transition: "0.3s", opacity: sendMobileCaptchaLoading ? 1 : 0}}><LoadingOutlined /> 发送中</div>
                                        <div style={{position: "absolute", marginLeft: 10, transition: "0.3s", opacity: sendMobileCaptchaLoading ? 0 : 1}}>{captchaButtonText}</div>
                                    </div>
                                </div>
                                <div style={{height: 20, fontSize: 12, marginLeft: 40, color: verifyCodeTrue ? "#fff" : "#f00", transition: "0.3s"}}>请输入验证码</div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{flex: 1, fontSize: 14}}>
                                        <ConfigProvider theme={{token: {colorPrimary: "#af8af6"}}}>
                                            <Checkbox style={{color: "#888"}} defaultChecked={true}>记住密码</Checkbox>
                                        </ConfigProvider>
                                    </div>
                                    <div style={{height: 40, width: 90, textAlign: "center", backgroundColor: "#af8af6", borderRadius: 30, color: "white", transition: "0.3s"}} onClick={() => login(verifyCode)}>
                                        <div style={{position: "absolute", margin: "7px 0 0 36px", transition: "0.3s", opacity: loginLoading ? 1 : 0}}><LoadingOutlined /></div>
                                        <div style={{position: "absolute", margin: "6px 0 0 28px", transition: "0.3s", opacity: loginLoading ? 0 : 1}}>登 录</div>
                                        {/*{loginLoading ? <><LoadingOutlined /></> : "登 录"}*/}
                                    </div>
                                </div>
                            </div>
                            {/*<div className="no-select" style={{textAlign: "right", textDecoration: "underline", cursor: "pointer"}}>游客访问</div>*/}
                        </div>
                    </div>

                </div>
                <div style={{display: "flex", alignItems: "center", flexDirection: "column", color: "#888", marginTop: 20}}>
                    <div>Copyright © 2021 - 2023 10KV.Co</div>
                    <a href="https://beian.miit.gov.cn/" target="_blank" style={{color: "#888", textDecoration: "underline"}}>沪ICP备2023020878号</a>
                </div>
            </div>
        </div>
);
};

export default Login;
