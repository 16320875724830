import React, {useEffect, useState} from 'react';
import {DownIcon, EditIcon, MenuIcon, MoreIcon, ProIcon, ShareIcon} from './icon';
import MessageBody from "./MessageBody";

import "./index.css"
import "./menu.css"
import "./messageList.css"
import History from "./History";
import InputBox from "./InputBox";
import ModelSelect from "./Model"
import {message, Modal} from "antd";
import {useRequest} from "ahooks";
import {doChatRequest2, doGetRecord} from "../../config/api";
import {scrollToBottom} from "./tools";
import {changeProject, submitQuestion} from "./Chat"
import Login from "./Login";

const ChatGPT = () => {

    const [project, setProject] = useState({});
    const [projectId, setProjectId] = useState(null);
    const [msgList, setMsgList] = useState([])
    const [model, setModel] = useState('chatgpt-4o-latest');
    const [question, setQuestion] = useState('');
    const [answerId, setAnswerId] = useState(null);
    const [showHistory, setShowHistory] = useState(window.innerWidth > 800);
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [showLogin, setShowLogin] = useState(false);


    const [loading, setLoading] = useState(false);
    const [submitting, setSubmit] = useState(false);
    const [answering, setAnswering] = useState(false);

    const clickHistory = (id) => {
        changeProject(projectId, setProjectId, id, setMsgList, setShowHistory, scrollToBottom, setLoading)
    }

    const submit = (question) => {
        submitQuestion(msgList, question, setMsgList, projectId, setProjectId, answerId, setAnswerId, setQuestion, scrollToBottom, submitting, setSubmit, setAnswering, model)
    }

    useEffect(() => {
        localStorage.setItem("answering", "")
        window.addEventListener("resize", () => {
            setShowHistory(window.innerWidth > 800)
            setInnerHeight(window.innerHeight)
        })

    }, [])

    return (
        <div style={{height: "100vh"}}>
            <div style={{height: innerHeight, width: "100vw", color: "#fff", display: "flex", position: "fixed"}}>
                <div style={{transition: "0.3s", width: showHistory ? 260 : 0, backgroundColor: "#171717", display: "flex", flexDirection: "column", overflow: "hidden"}}>
                    {/*左侧Header*/}
                    <div style={{display: "flex", alignItems: "center", padding: 20}}>
                        <div style={{cursor: "pointer"}} onClick={() => setShowHistory(!showHistory)}><MenuIcon/></div>
                        <div style={{flex: 1}}></div>
                        <div onClick={clickHistory} style={{cursor: "pointer"}}><EditIcon/></div>
                    </div>
                    {/*左侧Body*/}
                    <History projectId={projectId} changeProject={clickHistory} question={question} showLogin={showLogin}/>
                    {/*左侧Footer*/}
                    <div style={{display: "flex", alignItems: "center", padding: 20, gap: 10}}>
                        <div style={{border: "1px solid #979797", borderRadius: "50%", padding: 2}}>
                            <ProIcon/>
                        </div>
                        <div style={{cursor: "pointer", userSelect: "none"}}>
                            <div>Add Team workspace</div>
                            <div style={{color:"#979797", fontSize: 12}}>Collaborate on a Team plan</div>
                        </div>
                    </div>

                </div>
                <div style={{flex: 1, backgroundColor: "#212121", display: "flex", flexDirection: "column", overflow: "hidden"}}>
                    {/*右侧Header*/}
                    <div style={{display: "flex", alignItems: "center", padding: 20}}>
                        {showHistory ? null : <div style={{cursor: "pointer", marginRight: 10}} onClick={() => setShowHistory(!showHistory)}><MenuIcon/></div>}
                        {showHistory ? null : <div onClick={clickHistory} style={{cursor: "pointer"}}><EditIcon/></div>}
                        <div className="model-select-div" style={{userSelect: "none", color: "#b4b4b4", fontWeight: 600, fontSize: 20, display: "flex", alignItems: "center", cursor: "pointer", transition: "0.2s", padding: "0 10px", borderRadius: 8, marginLeft: 10}}>
                            ChatGPT 4o
                            <DownIcon/>
                            <ModelSelect/>
                        </div>
                        <div style={{flex: 1}}></div>
                        <ShareIcon/>
                        <div style={{marginLeft: 20, backgroundColor: "#aa67fd", width: 32, height: 32, borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 600, userSelect: "none"}}>
                            YC
                        </div>
                    </div>
                    {/*右侧Body*/}
                    <MessageBody msgList={msgList} loading={loading}/>
                    <InputBox submitQuestion={submit} question={question} setQuestion={setQuestion} submitting={submitting} answering={answering}/>
                </div>
            </div>
            <Login showLogin={showLogin} setShowLogin={setShowLogin}/>
        </div>
    )
}

export default ChatGPT;